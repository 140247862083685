<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>商品</el-breadcrumb-item><el-breadcrumb-item>商品管理</el-breadcrumb-item><el-breadcrumb-item>货品编辑（新）</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter">
        <!-- <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon>提交</el-button> -->
        <!-- <el-button type="default" @click="$router.push({ path: `/v1/goods/goods/list` })"><el-icon><close/></el-icon>取消</el-button> -->
      </div>
      <!-- <div class="ep-page-header-toolbar"></div> -->
    </el-header>
    <el-main class="ep-page-main">

      <el-card>
        <div class="m-step-wrap">
          <el-steps :active="activeStepIndex" align-center>
            <el-step title="基础信息" description="货品的基础信息" />
            <!-- <el-step title="分销信息" description="货品分销相关信息" /> -->
            <el-step title="申报信息" description="货品申报使用的相关信息" />
          </el-steps>
        </div>
      </el-card>

      <!-- 基础信息 START -->
      <el-card v-show="activeStepIndex == 0" style="margin:20px 0 80px 0;">
        <div class="m-card-wrap">
          <el-form ref="form1" :model="formModel" label-width="250px" status-icon :rules="form1Rules">
            <div class="ep-form-region-title">基础信息</div>
            <div class="m-form-region-wrap">
              <!-- <el-form-item label="所属商户：" prop="merchant_id">
                <el-select v-model="formModel.merchant_id" filterable placeholder="请选择" :disabled="pageMode=='update'" style="width:300px;" @change="onMerchantIdChange">
                  <el-option v-for="item in merchantsPairs" :key="item.merchant_id" :label="item.merchant_name" :value="item.merchant_id">
                    <span class="ep-select-option-left">{{ item.merchant_name }}</span>
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="所属店铺：" prop="shop_id">
                <el-select v-model="formModel.shop_id" filterable placeholder="请选择" :disabled="pageMode=='update'" style="width:300px;" @change="onShopIdChange">
                  <el-option v-for="item in shopPairs" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                    <span class="ep-select-option-left">{{ item.shop_name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="货品名称：" prop="sku_name">
                <el-input v-model="formModel.sku_name"/>
                <div v-if="pageMode=='update'" class="ep-cell-subtitle">原货品名称：{{ backupObject.sku_name }}</div>
              </el-form-item>
              <el-form-item label="货品编码：" prop="goods_sku">
                <el-input v-model="formModel.goods_sku"/>
                <div v-if="pageMode=='update'" class="ep-cell-subtitle">原货品编码：{{ backupObject.goods_sku }}</div>
              </el-form-item>
            </div>
            <el-divider/>
            <div class="m-form-region-wrap">
              <el-form-item label="店铺商品编码：" prop="outer_item_code">
                <el-input v-model="formModel.outer_item_code"/>
                <div class="ep-cell-subtitle">电商平台店铺内设置的商品编码</div>
              </el-form-item>
              <el-form-item label="店铺规格编码：" prop="outer_sku_code">
                <el-input v-model="formModel.outer_sku_code"/>
                <div class="ep-cell-subtitle">店铺规格编码</div>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-card>
      <!-- 基础信息 END -->


      <!-- <el-card v-show="activeStepIndex == 1" style="margin:20px 0 80px 0;">
      </el-card> -->


      <!-- 申报信息 START -->
      <el-card v-show="activeStepIndex == 1" style="margin:20px 0 80px 0;">
        <div class="m-card-wrap">
          <el-form ref="form3" :model="formModel" label-width="250px" v-loading="isLoading" status-icon :rules="form2Rules">
            <div class="ep-form-region-title">申报信息</div>
            <div class="m-form-region-wrap">

              <el-form-item label="账册序号：" prop="item_record_no">
                <el-input v-model="formModel.item_record_no" style="width:300px;" />
                <div class="ep-cell-subtitle">对应于总账册表体【序号】字段</div>
              </el-form-item>

              <el-form-item label="账册料号：" prop="item_no">
                <el-input v-model="formModel.item_no" style="width:300px;" />
                <div class="ep-cell-subtitle">对应于总账册表体【料号】字段</div>
              </el-form-item>

              <el-form-item label="海关HSCode：" prop="hs_code">
                <el-input v-model="formModel.hs_code" style="width:300px;" />
              </el-form-item>

              <el-form-item label="申报商品名称：" prop="item_name">
                <el-input v-model="formModel.item_name" />
              </el-form-item>

              <el-form-item label="商品规格：" prop="goods_spec">
                <el-input v-model="formModel.goods_spec" style="width:300px;" />
              </el-form-item>

              <el-form-item label="品牌：" prop="brand_name">
                <el-input v-model="formModel.brand_name" style="width:300px;" />
              </el-form-item>

              <el-form-item label="原产地：" prop="origin_code">
                <el-select v-model="formModel.origin_code" placeholder="请选择" filterable clearable style="width:300px;">
                  <el-option v-for="item in originPairs" :key="item.code" :label="item.name" :value="item.code">
                    <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="申报计量单位：" prop="unit_code">
                <el-select v-model="formModel.unit_code" placeholder="请选择" filterable clearable style="width:300px;">
                  <el-option v-for="item in unitPairs" :key="item.code" :label="item.name" :value="item.code">
                    <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                  </el-option>
                </el-select>
              </el-form-item>


              <el-form-item label="法定计量单位/系数：" prop="unit_code1">
                <el-select v-model="formModel.unit_code1" placeholder="请选择" filterable clearable style="width:300px;">
                  <el-option v-for="item in unitPairs" :key="item.code" :label="item.name" :value="item.code">
                    <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                  </el-option>
                </el-select>
                <el-input v-model="formModel.unit_code1_qty_base" style="width:300px;" />
                <div class="ep-cell-subtitle">与申报计量单位数量转换时相乘的系数</div>
              </el-form-item>

              <el-form-item label="第二计量单位/系数：" prop="unit_code2">
                <el-select v-model="formModel.unit_code2" placeholder="请选择" filterable clearable style="width:300px;">
                  <el-option v-for="item in unitPairs" :key="item.code" :label="item.name" :value="item.code">
                    <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                  </el-option>
                </el-select>
                <el-input v-model="formModel.unit_code2_qty_base" style="width:300px;" />
                <div class="ep-cell-subtitle">与申报计量单位数量转换时相乘的系数</div>
              </el-form-item>

              <div class="ep-form-separation" />

              <el-form-item label="毛重：" prop="gross_weight">
                <!-- <el-input-number v-model="formModel.gross_weight" :precision="3" :controls="false"> -->
                <el-input v-model="formModel.gross_weight" style="width:300px;">
                  <template #append>千克</template>
                </el-input>
              </el-form-item>
              <el-form-item label="净重：" prop="net_weight">
                <!-- <el-input-number v-model="formModel.gross_weight" :precision="3" :controls="false"> -->
                <el-input v-model="formModel.net_weight" style="width:300px;">
                  <template #append>千克</template>
                </el-input>
              </el-form-item>
              <el-form-item label="税率：" prop="tax_rate">
                <!-- <el-input-number v-model="formModel.gross_weight" :precision="3" :controls="false"> -->
                <el-input v-model="formModel.tax_rate" style="width:300px;">
                  <template #append>%</template>
                </el-input>
                <div class="ep-cell-subtitle">一般货物税率为9.100%，具体可查询商品税率</div>
              </el-form-item>

              <el-form-item label="条形码：" prop="barcode">
                <el-input v-model="formModel.barcode" style="width:300px;" />
              </el-form-item>
              <el-form-item label="成分描述：" prop="specification">
                <el-input v-model="formModel.specification" style="width:300px;" />
              </el-form-item>
              <el-form-item label="物流编码：" prop="kp_itemCode">
                <el-input v-model="formModel.kp_itemCode" style="width:300px;" />
              </el-form-item>
              <el-form-item label="申报属性标签：" prop="refs_declaration_tag">
                <el-select ref="refDeclarationTag" v-model="formModel.refs_declaration_tag" placeholder="请选择" filterable clearable style="width:300px;">
                  <el-option label="【无标签】" value="__NONE__" />
                  <el-option v-for="item in systemDeclarationTagPairs" :key="item.tag_id" :label="item.tag_name" :value="item.tag_id">
                    <span class="ep-select-option-left">{{ item.tag_name }}</span><span class="ep-select-option-right">{{ item.tag_code }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属仓库：" prop="store_code">
                <el-select ref="refStore" v-model="formModel.store_code" placeholder="请选择" filterable clearable style="width:300px;">
                  <el-option label="【默认】" value="__DEFAULT__" />
                  <el-option v-for="item in storePairs" :key="item.store_id" :label="item.store_name" :value="item.store_id">
                    <span class="ep-select-option-left">{{ item.store_name }}</span><span class="ep-select-option-right">{{ item.store_code }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-card>
      <!-- 申报信息 END -->


      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button type="primary" plain @click="onPrevStepClick" :disabled="this.activeStepIndex == 0"><el-icon><ArrowLeftBold/></el-icon>上一步</el-button>
          <el-button type="primary" plain @click="onNextStepClick" :disabled="this.activeStepIndex == 1"><el-icon><ArrowRightBold/></el-icon>下一步</el-button>
          <span class="ep-form-button-separation" />
          <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon>提交</el-button>
          <el-button type="default" @click="$router.push({ path: `/v1/goods/goods/list` })"><el-icon><close/></el-icon>取消</el-button>
        </div>
        <div class="ep-float-footer-pager"></div>
      </div>
      <!-- 数据页面固定footer END -->
    </el-main>

  </el-container>
</template>

<script>
import { Check, Close, ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue'
import { apiV1GetShopPairs, apiV1GetStorePairs } from '@/api/merchant.js'
// import { apiGetCustomsOriginPairs, apiGetCustomsUnitPairs } from '@/api/basedata.js'
import { CustomsOriginModel } from '@/model/basedata/CustomsOriginModel.js'
import { CustomsUnitModel } from '@/model/basedata/CustomsUnitModel.js'
import { CategoryCodeModel } from '@/model/goods/CategoryCodeModel.js'
import { apiV1GetDeclarationTagPairs } from '@/api/goods.js'
import { apiV1GetGoodsItemObject, apiV1InsertGoodsItem, apiV1UpdateGoodsItem } from '@/api/goods.js'
import AuthUtils from '@/utils/auth-utils.js'


export default {
  name: "sup-goods-item-edit-page",
  components: {
    Check, Close, ArrowLeftBold, ArrowRightBold,
  },
  props: {
    pageMode: {
      type: String,
      default: 'insert'
    },
  },
  data() {
    return {
      isLoading: false,
      sku_id: '',
      backupObject: {}, // 备份完整商品信息对象
      //
      merchantsPairs: [], // 商户列表
      shopPairs: [], // 店铺列表
      originPairs: [], // 原产地
      unitPairs: [], // 单位
      categoryCodePairs: [],
      systemDeclarationTagPairs: [], // 申报属性标签
      storePairs: [],
      //
      formModel: {
        sku_id: '',
        merchant_id: '',
        shop_id: '',
        sku_name: '',
        goods_sku: '',
        outer_item_code: '',
        outer_sku_code: '',
        //
        item_record_no: '',
        item_no: '',
        hs_code: '',
        item_name: '',
        goods_spec: '',
        brand_name: '',
        //
        origin_code: '',
        unit_code: '',
        unit_code1: '',
        unit_code1_qty_base: '',
        unit_code2: '',
        unit_code2_qty_base: '',
        //
        gross_weight: '0',
        net_weight: '0',
        tax_rate: '', // total_tax_rate
        barcode: '',
        specification: '',
        //
        kp_itemCode: '', // logistics_goods_sn
        refs_declaration_tag: '',
        store_code: '__DEFAULT__',
      },
      // 检验规则
      form1Rules: {
        merchant_id: [{required: true, message: '请输入所属商户', trigger: 'blur'}],
        shop_id: [{required: true, message: '请输入所属店铺', trigger: 'blur'}],
        sku_name: [{required: true, message: '请输入货品名称', trigger: 'blur'}],
        goods_sku: [{required: true, message: '请输入货品编码', trigger: 'blur'}],
        // outer_item_code: [{required: true, message: '请输入店铺商品编码', trigger: 'blur'}],
        // outer_sku_code: [{required: true, message: '请输入店铺规格编码', trigger: 'blur'}],
      },
      form2Rules: {},
      // 拷贝规则
      cloneFields: [
        'merchant_id', 'shop_id', 'sku_name', 'goods_sku', 'outer_item_code', 'outer_sku_code',
        'item_record_no', 'item_no', 'hs_code', 'item_name', 'goods_spec', 'brand_name',
        'origin_code', 'unit_code', 'unit_code1', 'unit_code1_qty_base', 'unit_code2', 'unit_code2_qty_base',
        'gross_weight', 'net_weight', {key: 'tax_rate', default: ''}, 'barcode', 'specification',
        'kp_itemCode', 'refs_declaration_tag', {key: 'store_code', default: '__DEFAULT__'},
      ],

      activeStepIndex: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    // 初始化页面
    initPage() {
      this.getObject()
    },
    // 获取完整商品信息对象
    getObject() {
      if (this.pageMode == 'update') {
        if (this.sku_id != '') {
          this.isLoading = true
          apiV1GetGoodsItemObject({
            sku_id: this.sku_id,
          }).then((res) => {
            this.backupObject = res.data.data

            if (this.backupObject.merchant_id != '') {
              this.getShopDataByMerchantId(this.backupObject.merchant_id)
            }

            this.formModel = this.$$utils.cloneObject(this.backupObject, this.cloneFields)
            this.isLoading = false
          })
        }
      } else {
        this.formModel = this.$$utils.cloneObject({}, this.cloneFields)
        this.sup_goods_item_id = ''
      }
    },


    // ===================================================================================================

    // 当商户改变时 触发
    onMerchantIdChange(newValue) {
      this.shopPairs = []
      this.formModel.shop_id = ''
      this.getShopDataByMerchantId(newValue)
    },
    // 当店铺改变时 触发
    onShopIdChange(newValue) {
      this.getStorePairData()
    },
    // 点击触发 - 上一步
    onPrevStepClick() {
      this.activeStepIndex = (this.activeStepIndex - 1 > 0) ? this.activeStepIndex - 1 : 0
    },
    onNextStepClick() {
      this.activeStepIndex = (this.activeStepIndex + 1 < 2) ? this.activeStepIndex + 1 : 1
    },
    // 点击提交按钮 触发
    onSubmit() {
      this.$refs['form1'].validate((validResult) => {
        if (validResult === false) { this.$message.error('请正确填写【基础信息】'); return false }
      
          let sendData = this.$$utils.cloneObject(this.formModel, this.cloneFields)

          sendData.origin_code_name = this.code2Name(this.originPairs, this.formModel.origin_code)
          sendData.unit_code_name = this.code2Name(this.unitPairs, this.formModel.unit_code)
          sendData.unit_code1_name = this.code2Name(this.unitPairs, this.formModel.unit_code1)
          sendData.unit_code2_name = this.code2Name(this.unitPairs, this.formModel.unit_code2)

          if (this.pageMode == 'update') {
            sendData.sku_id = this.sku_id
            apiV1UpdateGoodsItem(sendData).then((res) => {
              if (res.data.code == 0) { this.$message.success('操作成功') } else { this.$message.error(res.data.message) }
            })
          } else {
            apiV1InsertGoodsItem(sendData).then((res) => {
              if (res.data.code == 0) { this.$message.success('操作成功') } else { this.$message.error(res.data.message) }
            })
          }
      
      })


    },


    // ===================================================================================================


    // 获取供应商结构对数据
    getMerchantsPairData() {
      if (this.merchantsPairs.length == 0) {
        apiV1GetMerchantsPairs({ merchant_type: 0 }).then((res) => {
          this.merchantsPairs = res.data.data;
        })
      }
    },
    // 根据商户ID获取店铺pair
    getShopDataByMerchantId(merchant_id) {
      if (this.shopPairs.length == 0) {
        apiV1GetShopPairs({merchant_id: merchant_id}).then((res) => {
          this.shopPairs = res.data.data;
        })
      }
    },

    // 获取进口地pair
    // getCustomsOriginPairData() {
    //   if (this.originPairs.length == 0) {
    //     apiGetCustomsOriginPairs().then((res) => {
    //       this.originPairs = res.data.data;
    //     })
    //   }
    // },
    // 获取单位pair
    // getCustomsUnitPairData() {
    //   if (this.unitPairs.length == 0) {
    //     apiGetCustomsUnitPairs().then((res) => {
    //       this.unitPairs = res.data.data;
    //     })
    //   }
    // },

    // 获取进口地pair
    getCustomsOriginPairData() {
      if (this.originPairs.length == 0) {
        var vueThis = this
        let promise1 = new Promise(function(resolve, reject) {
          vueThis.originPairs = CustomsOriginModel.getPairData(); resolve()
        });
      }
    },
    // 获取单位pair
    getCustomsUnitPairData() {
      var vueThis = this
      if (this.unitPairs.length == 0) {
        let promise2 = new Promise(function(resolve, reject) {
          vueThis.unitPairs = CustomsUnitModel.getPairData(); resolve()
        });
      }
    },
    // 获取类目编码pair
    getCategoryCodeMPairData() {
      var vueThis = this
      if (this.categoryCodePairs.length == 0) {
        let promise2 = new Promise(function(resolve, reject) {
          vueThis.categoryCodePairs = CategoryCodeModel.getPairData(); resolve()
        });
      }
    },
    // 获取商品申报属性标签pair
    getSystemDeclarationTagPairData() {
      if (this.systemDeclarationTagPairs.length == 0) {
        apiV1GetDeclarationTagPairs().then((res) => {
          this.systemDeclarationTagPairs = res.data.data;
        })
      }
    },
    // 获取店铺仓库列表
    getStorePairData() {
      if (this.formModel.shop_id != '') {
        apiV1GetStorePairs({shop_id: this.formModel.shop_id}).then((res) => {
          this.storePairs = res.data.data;
        })
      }
    },

    // ===================================================================================================

    code2Name(dataArray, key) {
      var name = ''
      dataArray.forEach((value) => {
        if (value.code == key) {
          name = value.name
        }
      });
      return name
    },

  },
  mounted() {
    // console.log(this.pageMode)
    this.sku_id = this.$route.params.id || ''

    // this.getMerchantsPairData()
    this.getShopDataByMerchantId(AuthUtils.getUserId())
    this.getCustomsOriginPairData()
    this.getCustomsUnitPairData()
    this.getCategoryCodeMPairData()
    this.getSystemDeclarationTagPairData()
    this.getStorePairData()

    this.$nextTick(() => {
      this.initPage()
    })
  }
}
</script>

<style scoped>
.m-step-wrap { width: 800px; margin-left: auto; margin-right: auto; padding: 20px; }
.m-card-wrap { width: 800px; margin-left: auto; margin-right: auto; padding: 20px; }
.m-form-region-wrap { width: 500px; }
</style>
