<template>
  <div class="ep-form-panel">
    <el-form ref="form1" :model="formModel" label-width="100px" status-icon :rules="form1Rules">
      <div class="ep-form-region-title">基础信息</div>
      <!-- <el-form-item label="所属商户：" prop="merchant_id">
        <el-select v-model="formModel.merchant_id" filterable placeholder="请选择" :disabled="editMode=='update'" style="min-width:160px;" @change="onMerchantIdChange">
          <el-option v-for="item in merchantsPairs" :key="item.merchant_id" :label="item.merchant_name" :value="item.merchant_id">
            <span class="ep-select-option-left">{{ item.merchant_name }}</span>
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="所属店铺：" prop="shop_id">
        <el-select v-model="formModel.shop_id" filterable placeholder="请选择" :disabled="editMode=='update'" style="min-width:160px;">
          <el-option v-for="item in shopPairs" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
            <span class="ep-select-option-left">{{ item.shop_name }}</span>
            <!-- <span class="ep-select-option-right">{{ item.code }}</span> -->
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称：" prop="goods_name">
        <el-input v-model="formModel.goods_name"/>
        <div v-if="editMode=='update'" class="ep-cell-subtitle">原商品名称：{{ mGoodsObject.goods_name }}</div>
      </el-form-item>
      <el-form-item label="商品编码：" prop="goods_sn">
        <el-input v-model="formModel.goods_sn"/>
        <div v-if="editMode=='update'" class="ep-cell-subtitle">原商品编码：{{ mGoodsObject.goods_sn }}</div>
      </el-form-item>

      <!-- <div class="ep-form-separation" /> -->
      <!-- <div class="ep-card-title">关区信息</div> -->
      <!-- <el-form-item label="关区编码：" prop="kj_customs_code">
        <el-select v-model="formModel.kj_customs_code" placeholder="请选择...">
          <el-option label="【默认】" value="__DEFAULT__" />
          <el-option v-for="(item, key) in customsCodePairs" :key="key" :value="item.customs_code" 
            :label="`${item.customs_code_name} - ${item.customs_code}`">
            <span class="ep-select-option-left">{{ item.customs_code_name }}</span>
            <span class="ep-select-option-right">{{ item.customs_code }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="贸易方式：" prop="kj_trade_mode">
        <el-select v-model="formModel.kj_trade_mode" placeholder="请选择...">
          <el-option label="【默认】" value="__DEFAULT__" />
          <el-option label="B2B2C保税模式 - 1210" value="1210" />
          <el-option label="B2C直邮模式 - 9610" value="9610" />
        </el-select>
      </el-form-item>
      <el-form-item label="物流商编码：">
        <el-select v-model="formModel.kj_logistics_code" placeholder="请选择">
          <el-option label="【默认】" value="__DEFAULT__" />
          <el-option v-for="(item, key) in logisticsCodePairs" :key="key" :value="item.logistics_code" 
            :label="`${item.logistics_code_name} - ${item.logistics_code}`">
            <span class="ep-select-option-left">{{ item.logistics_code_name }}</span>
            <span class="ep-select-option-right">{{ item.logistics_code }}</span>
          </el-option>
        </el-select>
      </el-form-item> -->

    </el-form>
  </div>
</template>

<script>
import AuthUtils from '@/utils/auth-utils.js';
import { apiGetMerchantsPairs, apiGetShopPairsByMerchantId, apiGetMerchantCustomsCodePairs, apiGetMerchantLogisticsCodePairs } from '@/api/merchant.js';

export default {
  name: 'goods-info-view',
  components: {
  },
  props: {
  },
  data() {
    return {
      editMode: '',
      goods_id: '',
      formModel: {
        goods_id: '',
        goods_name: '',
        merchant_id: '',
        shop_id: '',
        goods_sn: '',
        // kj_customs_code: '',
        // kj_trade_mode: '',
        // kj_logistics_code: '',
        is_multi_spec: 0,
      },
      mGoodsObject: {}, // 商品对象
      merchantsPairs: [], // 商户列表
      shopPairs: [], // 店铺列表
      // customsCodePairs: [], // 关区编码
      // logisticsCodePairs: [], // 物流商编码
      // 检验规则
      form1Rules: {
        merchant_id: [{required: true, message: '请输入所属商户', trigger: 'blur'}],
        shop_id: [{required: true, message: '请输入所属店铺', trigger: 'blur'}],
        goods_name: [{required: true, message: '请输入商品名称', trigger: 'blur'}],
        goods_sn: [{required: true, message: '请输入商品编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'goods_name', 'merchant_id', 'shop_id', 'goods_sn',
        // 'kj_customs_code', 'kj_trade_mode', 'kj_logistics_code',
        // {key: 'is_multi_spec', default: 0},
        // {key: 'kj_customs_code', default: '__DEFAULT__'},
        // {key: 'kj_trade_mode', default: '__DEFAULT__'},
        // {key: 'kj_logistics_code', default: '__DEFAULT__'},
      ],
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 初始化view
    initView({editMode, goodsObject}) {
      this.editMode = editMode
      this.mGoodsObject = goodsObject

      this.formModel = this.$$utils.cloneObject(goodsObject, this.cloneFields)
      this.formModel.merchant_id = AuthUtils.getUserId()
      if (this.formModel.merchant_id != '') {
        this.getShopDataByMerchantId(this.formModel.merchant_id)
      }
    },
    // 获取值
    getData() {
      this.$refs['form1'].validate((validResult) => {
        if (validResult === false) {
          this.$message.error('请正确填写规格数据')
          return false
        }
      })
      let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)
      return formData
    },

    // ===================================================================================================

    // 当商户改变时 触发
    onMerchantIdChange(newValue) {
      // this.customsCodePairs = []
      // this.logisticsCodePairs = []
      // this.formModel.kj_customs_code = '__DEFAULT__'
      // this.formModel.kj_trade_mode = '__DEFAULT__'
      // this.formModel.kj_logistics_code = '__DEFAULT__'

      // this.getMerchantCustomsCodePairData()
      // this.getMerchantLogisticsCodePairData()

      // this.$store.commit('setGoodsEditMerchantId', newValue)
      this.shopPairs = []
      this.getShopDataByMerchantId(newValue)
    },

    // ===================================================================================================

    // 获取商户结构对数据
    getMerchantsPairData() {
      if (this.merchantsPairs.length == 0) {
        apiGetMerchantsPairs().then((res) => {
          this.merchantsPairs = res.data.data;
        })
      }
    },
    // 根据商户ID获取店铺pair
    getShopDataByMerchantId(merchant_id) {
      if (this.shopPairs.length == 0) {
        apiGetShopPairsByMerchantId({merchant_id: merchant_id}).then((res) => {
          this.shopPairs = res.data.data;
        })
      }
    },


    // 获取商户对应的关区编码
    // getMerchantCustomsCodePairData() {
    //   if (this.formModel.merchant_id == '') {
    //     return
    //   }
    //   if (this.customsCodePairs.length == 0) {
    //     apiGetMerchantCustomsCodePairs({
    //       merchant_id: this.formModel.merchant_id,
    //     }).then((res) => {
    //       this.customsCodePairs = res.data.data;
    //     })
    //   }
    // },
    // 获取商户对应的物流商·编码
    // getMerchantLogisticsCodePairData() {
    //   if (this.formModel.merchant_id == '') {
    //     return
    //   }
    //   if (this.logisticsCodePairs.length == 0) {
    //     apiGetMerchantLogisticsCodePairs({
    //       merchant_id: this.formModel.merchant_id,
    //     }).then((res) => {
    //       this.logisticsCodePairs = res.data.data;
    //     })
    //   }
    // },

  },
  mounted() {
    this.$nextTick(() => {
      // this.getMerchantsPairData()
      this.formModel.merchant_id = AuthUtils.getUserId()
      this.getShopDataByMerchantId(AuthUtils.getUserId())
    })
  }
};
</script>

<style scoped>
/* .el-form { */
  /* width: 400px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
/* } */
</style>
