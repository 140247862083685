<template>
  <div class="ep-form-panel">
    <!-- 单规格/多规格切换按钮 START -->
    <el-form ref="formSpec" label-width="200px" status-icon>
      <!-- <el-form-item label="规格情况：" prop="isMultiSpec">
        <el-switch v-model="mIsMultiSpec" active-text="多规格" inactive-text="单规格" :active-value="1" :inactive-value="0"
          :disabled="canNotChangeIsMultiSpecField" @change="onIsMultiSpecFieldChange" />
      </el-form-item> -->
    </el-form>
    <!-- <div class="ep-form-separation" /> -->
    <!-- 单规格/多规格切换按钮 END -->
    <!-- 规格列表 START -->
    <div class="ep-table-wrap">
      <div class="ep-table-header">
        <div class="ep-drawer-region-title">
          <span>规格列表</span>
          <span class="ep-page-title-separation" style="line-height:16px;" />
          <span class="ep-action-button primary" @click="onAddSkuClick">添加规格</span>
        </div>
        <div style="padding:0 0 20px 0;">
          <el-alert type="warning" show-icon :closable="false" title="商品规格"
            description="请根据平台店铺的商品规格设置情况来选择。特别是多规格，必须一一对应设置好规格编码，报关系统才能正确识别出订单商品" />
        </div>    
        <goods-sku-edit-view ref="goodsSkuEditView" @submit="onGoodsSkusUpdated" />
        <!-- 搜索栏 START -->
        <!-- <goods-search-view ref="searchView" /> -->
        <!-- 搜索栏 END -->
      </div>
      <!-- 表格 START -->
      <div class="ep-table-content">
        <el-table ref="epDataTable" fit stripe highlight-current-row v-loading="epIsLoading" :header-cell-style="{background:'#79bbff',color:'#fefefe'}"
          :data="mGoodsSkus" @selection-change="onTableSelectionChange">
          <el-table-column type="selection" width="50" />
          <el-table-column label="完整规格名称" fixed="left" min-width="200" >
            <template #default="scope">
              {{ scope.row.sku_name }}
              <div v-if="scope.row.declaration_tag_name != ''"><el-tag type="success">{{ scope.row.declaration_tag_name }}</el-tag></div>
              <!-- <div class="ep-cell-subtitle">{{ scope.row.goods_id }}</div> -->
            </template>
          </el-table-column>

          <el-table-column prop="goods_sku" label="规格编码" fixed="left" min-width="150" />
          <!-- <el-table-column prop="origin_code_name" label="原产地" min-width="150" /> -->
          <el-table-column prop="outer_item_id" label="店铺商品编码" min-width="150" />
          <el-table-column prop="outer_sku_id" label="店铺规格编码" min-width="150" />

          <!-- <el-table-column prop="origin_code_name" label="原产地" min-width="150" /> -->
          <!-- <el-table-column prop="unit_code_name" label="单位" min-width="150" /> -->
          <!-- <el-table-column prop="gross_weight" label="毛重（Kg）" min-width="150" /> -->
          <!-- <el-table-column prop="net_weight" label="净重（Kg）" min-width="150" /> -->
          

          <!-- <el-table-column label="完整规格名称" fixed="left" min-width="200" >
            <template slot-scope="scope">
              {{ scope.row.sku_name }}
            </template>
          </el-table-column> -->


          <!-- <el-table-column prop="cus_goods_no" label="海关备案号" min-width="150" /> -->
          <!-- <el-table-column prop="ciq_goods_no" label="国检备案号" min-width="150" /> -->
          <!-- <el-table-column prop="hs_code" label="海关HSCode" min-width="150" /> -->

          <!-- <el-table-column prop="goods_spec" label="商品规格" min-width="150" /> -->
          <!-- <el-table-column prop="brand_name" label="品牌" min-width="150" /> -->
          <!-- <el-table-column prop="tax_rate" label="税率（%）" min-width="100" /> -->

          <el-table-column label="操作" fixed="right" min-width="150">
            <template #default="scope">
              <span class="ep-action-button primary" @click="onUpdateSkuClick(scope.row)">编辑</span>
              <span class="ep-action-line"/>
              <span class="ep-action-button danger" @click="onDeleteSkuClick(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 表格 END -->
      <!-- 表格分页功能栏 START -->
      <div class="ep-basetable-footer">
        <div class="ep-basetable-footer-toolbar"></div>
        <div class="ep-basetable-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>

      <!-- 表格分页功能栏 END -->
    </div>
    <!-- 规格列表 END -->
  </div>
</template>

<script>
import BasePageMixin from '@/mixin/BasePageMixin.js'
import DataTableMixin from '@/mixin/DataTableMixin.js'
import GoodsSkuEditView from '@/views/v1/goods/goods/edit/GoodsSkuEditView'
import { apiGetCustomsOriginPairs, apiGetCustomsUnitPairs } from '@/api/basedata.js';

export default {
  mixins: [ BasePageMixin, DataTableMixin ],
  name: 'goods-spec-view',
  components: {
    GoodsSkuEditView,
  },
  props: {
    // inIsMultiSpec: Number,
    // inGoodsSkus: Array,
  },
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      goods_id: '',
      formModel: {
        rndIndex: '', // 随机序号
        sku_id: '',
        goods_sku: '',
        yz_sku_id: '',
        origin_code: '',
        unit_code: '',
        gross_weight: '0',
        net_weight: '0',
        cus_goods_no: '',
        ciq_goods_no: '',
        hs_code: '',
        goods_spec: '',
        brand_name: '',
        //
        kj_customs_code: '',
        kj_trade_mode: '',
        logistics_app_code: '',
      },

      // formSpec: {
      //   mIsMultiSpec: 0,
        
      // },
      mIsMultiSpec: 0, // 商品单规格/多规格标志
      mGoodsSkus: [], // 商品的SKUs列表
      canNotChangeIsMultiSpecField: false, // 单规格/多规格切换按钮禁用

      originPairs: [],
      unitPairs: [],
      // departmentPairs: [],
      // rolePairs: [],
      // checkRolePairs: [],

      //
      logistics_plugin_list: [],

      // 检验规则
      formRules: {
      },
      // 拷贝规则
      cloneFields: [
        'goods_sku', 'yz_sku_id', 'origin_code', 'unit_code', 'gross_weight', 'net_weight', 'cus_goods_no', 'ciq_goods_no', 'hs_code',
        'goods_spec', 'brand_name',
        // {key: 'sex', default: 0},
      ],
    }
  },
  computed: {
    // cIsMultiSpec: () => {
    //   return this.isMultiSpec
    // },
    // cGoodsSkus: () => {
    //   return this.goodsSkus
    // },
  },
  watch: {
    // 商品单规格/多规格标志状态监听切换
    // isMultiSpec: function (newValue, oldValue) {
    //   // console.log(newValue)
    //   this.mIsMultiSpec = newValue
    // },
    mIsMultiSpec: function (newValue, oldValue) {
      // console.log(newValue)
      this._GetCanNotChangeIsMultiSpecField()
    },
    mGoodsSkus: function (newValue, oldValue) {
      // console.log(newValue)
      this._GetCanNotChangeIsMultiSpecField()
    },
  },
  methods: {
    // 初始化view
    initView({inIsMultiSpec = 0, inGoodsSkus = []}) {
      // if (data) {
      //   this.formModel = this.$$utils.cloneObject(data, this.cloneFields)
      // }
      this.mIsMultiSpec = inIsMultiSpec
      this.mGoodsSkus = inGoodsSkus
      
    },
    // 获取值
    getData() {
      let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)
      return formData
    },

    // 当单规格/多规格开关切换时触发
    onIsMultiSpecFieldChange(newValue) {
      this.$emit('goods-skus-change', newValue, this.mGoodsSkus)
    },
    // 编辑完SKU回调
    onGoodsSkusUpdated(editMode, newGoodsSku) {
      if (editMode == 'insert') {
        this.mGoodsSkus.push(newGoodsSku)
      } else {
        var newGoodsSkus = []
        this.mGoodsSkus.forEach((value) => {
          if (value.rndIndex == newGoodsSku.rndIndex) {
            newGoodsSkus.push(newGoodsSku)
          } else {
            newGoodsSkus.push(value)
          }
        });
        this.mGoodsSkus = newGoodsSkus
      }
      this.$emit('goods-skus-change', 0, this.mGoodsSkus)
    },
    // 点击添加SKU触发
    onAddSkuClick() {
      this.$refs.goodsSkuEditView.showView({ editMode: "insert" })
    },
    // 点击编辑SKU触发
    onUpdateSkuClick(row) {
      this.$refs.goodsSkuEditView.showView({ editMode: "update", goodsSkuObj: row })
    },
    // 点击删除SKU触发
    onDeleteSkuClick(row) {
      this.resolveDeleteConfirm().then(() => {
        var targetIndex = 0
        this.mGoodsSkus.forEach((value, index) => {
          if (value.rndIndex == row.rndIndex) {
            targetIndex = index
          }
        })
        this.mGoodsSkus.splice(targetIndex, 1)
      }).catch(()=>{})
    },




    // ===================================================================================================

    // 获取进口地pair
    getCustomsOriginPairData() {
      if (this.originPairs.length == 0) {
        apiGetCustomsOriginPairs().then((res) => {
          this.originPairs = res.data.data;
        })
      }
    },
    // 获取单位pair
    getCustomsUnitPairData() {
      if (this.unitPairs.length == 0) {
        apiGetCustomsUnitPairs().then((res) => {
          this.unitPairs = res.data.data;
        })
      }
    },
    // 处理 单规格/多规格开关 禁用逻辑
    _GetCanNotChangeIsMultiSpecField() {
      let goodsSkusLength = this.mGoodsSkus.length
      // console.log('mIsMultiSpec' + this.mIsMultiSpec)
      // console.log('goodsSkusLength' + goodsSkusLength)
      if (this.mIsMultiSpec == 1 && goodsSkusLength > 1) {
        this.canNotChangeIsMultiSpecField = true
      } else {
        this.canNotChangeIsMultiSpecField = false
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(this.mIsMultiSpec)
      // console.log(this.mGoodsSkus)
      // this.mIsMultiSpec = this.inIsMultiSpec
      // this.mGoodsSkus = this.inGoodsSkus
    })
  }
};
</script>

<style scoped>
.el-form {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>
