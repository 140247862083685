import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 商品相关
// ================================================================================================

// 获取商品分页
export function apiGetGoodsPage(data) {
    return request({
        url: `/api/merchant/goods/goods`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 获取商品对象
export function apiGetGoodsObject(data) {
    return request({
        url: `/api/merchant/goods/goods/${data.goods_id}`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 添加商品
export function apiInsertGoods(data) {
    return request({
        url: `/api/merchant/goods/good`,
        method: 'post',
        data,
        token: true,
    })
}

// 修改商品
export function apiUpdateGoods(data) {
    return request({
        url: `/api/merchant/goods/goods/${data.goods_id}`,
        method: 'put',
        data,
        token: true,
    })
}

// 删除商品
export function apiDeleteGoods(data) {
    return request({
        url: `/api/merchant/goods/goods/${data.goods_id}`,
        method: 'delete',
        data,
        token: true,
    })
}




// ================================================================================================
// 商品申报属性标签
// ================================================================================================


// 查询键值对 商品申报属性标签
export function apiGetSystemDeclarationTagPairs(data) {
    return request({url: `/api/admin/goods/system-declaration-tag-pairs`, method: 'get', params: data, token: true})
}

// 查询键值对 申报属性标签
export function apiV1GetDeclarationTagPairs(data) {
    return request({url: `/api/admin/v1/goods/declaration-tag-pairs`, method: 'get', params: data, token: true})
}


// ================================================================================================
// 商品 - 货品SKU V1
// ================================================================================================

// 获取分页 货品SKU
export function apiV1GetGoodsItemPage(data) { return request({url: `/api/admin/v1/goods/goods-items`, method: 'get', params: data, token: true}) }
// 获取对象 货品SKU
export function apiV1GetGoodsItemObject(data) { return request({url: `/api/admin/v1/goods/goods-items/${data.sku_id}`, method: 'get', params: data, token: true}) }
// 添加 货品SKU
export function apiV1InsertGoodsItem(data) { return request({url: `/api/admin/v1/goods/goods-item`, method: 'post', data, token: true}) }
// 修改 货品SKU
export function apiV1UpdateGoodsItem(data) { return request({url: `/api/admin/v1/goods/goods-items/${data.sku_id}`, method: 'put', data, token: true}) }
// 删除 货品SKU
export function apiV1DeleteGoodsItem(data) { return request({url: `/api/admin/v1/goods/goods-items/${data.sku_id}`, method: 'delete', data, token: true}) }




// ================================================================================================


// ================================================================================================
// 商品 - 供应商货品 V1
// ================================================================================================

// 搜索 货品
export function apiV1ActionSearchSupGoodsItem(data) { return request({url: `/api/admin/v1/goods/sup-goods-items/actions/search`, method: 'get', params: data, token: true}) }
// 选品 货品
export function apiV1ActionSelectSupGoodsItem(data) { return request({url: `/api/admin/v1/goods/sup-goods-items/actions/select`, method: 'get', params: data, token: true}) }
// 获取分页 供应商货品
export function apiV1GetSupGoodsItemPage(data) { return request({url: `/api/admin/v1/goods/sup-goods-items`, method: 'get', params: data, token: true}) }
// 获取对象 供应商货品
export function apiV1GetSupGoodsItemObject(data) { return request({url: `/api/admin/v1/goods/sup-goods-items/${data.sup_goods_item_id}`, method: 'get', params: data, token: true}) }

// 选品 批量选品
export function apiV1ActionBatchSelectSupGoodsItem(data) { return request({url: `/api/admin/v1/goods/sup-goods-items/actions/batch-select`, method: 'post', data, token: true}) }

// ================================================================================================
// 商品 - 组合商品 V1
// ================================================================================================

// 获取分页 供应商货品
export function apiV1GetCombineGoodsPage(data) { return request({url: `/api/admin/v1/goods/combine-goods`, method: 'get', params: data, token: true}) }
// 获取对象 供应商货品
export function apiV1GetCombineGoodsObject(data) { return request({url: `/api/admin/v1/goods/combine-goods/${data.combine_goods_id}`, method: 'get', params: data, token: true}) }
// 添加 供应商货品
export function apiV1InsertCombineGoods(data) { return request({url: `/api/admin/v1/goods/combine-good`, method: 'post', data, token: true}) }
// 修改 供应商货品
export function apiV1UpdateCombineGoods(data) { return request({url: `/api/admin/v1/goods/combine-goods/${data.combine_goods_id}`, method: 'put', data, token: true}) }
// 删除 供应商货品
export function apiV1DeleteCombineGoods(data) { return request({url: `/api/admin/v1/goods/combine-goods/${data.combine_goods_id}`, method: 'delete', data, token: true}) }

// 搜索 店铺的SKU
export function apiV1ActionSearchSku(data) { return request({url: `/api/admin/v1/goods/combine-goods/actions/search-sku`, method: 'get', params: data, token: true}) }


// ================================================================================================
// 商品 - 菜鸟商品 V1
// ================================================================================================
// 查询分页 菜鸟商品库存
export function apiV1CainiaoGoodsQuantityPage(data) { return request({url: `/api/admin/v1/goods/cainiao-goods-quantity`, method: 'get', params: data, token: true}) }
// 操作 同步商品库存
export function apiV1CainiaoGoodsQuantityActionSyncQuantity(data) { return request({url: `/api/admin/v1/goods/cainiao-goods-quantity/actions/sync-quantity`, method: 'get', params: data, token: true}) }
// 操作 同步商品库存
export function apiV1CainiaoGoodsQuantityActionBindGoods(data) { return request({url: `/api/admin/v1/goods/cainiao-goods-quantity/actions/bind-goods`, method: 'get', params: data, token: true}) }
