<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>商品</el-breadcrumb-item><el-breadcrumb-item>商品</el-breadcrumb-item><el-breadcrumb-item>商品上传</el-breadcrumb-item></el-breadcrumb>
      </div>
      <!-- <div class="ep-page-header-filter"></div> -->
      <!-- <div class="ep-page-header-toolbar"></div> -->
    </el-header>
    <el-container style="padding:0 20px;">
      <!-- 左侧操作栏 START -->
      <el-aside style="padding: 0;" width="450px">
        <div class="ep-form-panel">
          <el-form ref="formUpload" :model="formModel" label-width="100px" v-loading="isLoading" status-icon>
            <!-- 模板文件信息 START -->
            <div class="ep-form-region-title">模板文件信息</div>
            <!-- <el-form-item label="模板类型：" prop="template_type">
              <el-radio-group v-model="template_type" style="width:100%;">
                <el-radio-button label="Guangzhou" border>广州关区</el-radio-button>
                <el-radio-button label="Haikou" border>海口关区</el-radio-button>
              </el-radio-group>
              <div class="es-cell-subtitle">根据关区选择模板类型</div>
            </el-form-item> -->
            <el-form-item label="模板文件：" prop="template_url">
              <div style="width:100%;text-align:left;">
                <el-link :href="templateSettingMap[`${template_type}`].href" :download="templateSettingMap[`${template_type}`].download">下载地址</el-link>
              </div>
              <div class="ep-cell-subtitle">当前版本：{{ templateSettingMap[`${template_type}`].version }}</div>
            </el-form-item>
            <!-- 模板文件信息 END -->

            <!-- 模板文件上传 START -->
            <div class="ep-form-region-title">模板文件上传</div>
            <div class="m-file-wrap">
              <el-upload name="goodsFile" ref="uploadView" drag :auto-upload="false" multiple :limit="1" 
                v-model:file-list="uploadFiles"
                :action="templateSettingMap[`${template_type}`].uploadUrl"
                :on-success="onUploadSuccess" :on-error="onUploadError">
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <template #tip><div class="el-upload__tip">只能上传xls/xlsx文件</div></template>
              </el-upload>
              <el-button type="primary" icon="el-icon-upload" class="m-upload-button" :loading="isUploading" @click="onUploadClick">导入</el-button>
            </div>
            <!-- 模板文件上传 END -->
          </el-form>
        </div>
      </el-aside>
      <!-- 左侧操作栏 END -->

      <el-main class="ep-page-main" style="margin-left:20px;padding:10px 10px;background:white;">

        <div class="es-table-wrap">
          <div class="es-table-header">
            <div class="ep-form-region-title">导入结果</div>
            <!-- 搜索栏 START -->
            <!-- <goods-search-view ref="searchView" @search="onSearched"/> -->
            <!-- 搜索栏 END -->

            <template v-if="uploadResult === 0">
              <el-alert title="操作结果" type="success" show-icon><div>导入操作成功</div></el-alert>
            </template>
            <template v-else-if="uploadResult === 1">
              <el-alert title="操作结果" type="error" show-icon><div>导入操作失败，请按照提示修改导入模板文件数据</div></el-alert>
            </template>

          </div>
          <!-- 表格 START -->
          <div class="es-table-content">
            <el-table ref="epDataTable" fit size="small" stripe border highlight-current-row v-loading="epIsLoading" 
            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            :data="epDataList" @selection-change="onTableSelectionChange">

              <!-- <el-table-column type="selection" width="50" /> -->

              <el-table-column label="错误数量" width="100" fixed="left" align="center">
                <template #default="scope"><el-tag type="danger" effect="dark">{{ scope.row.error_count }}</el-tag></template>
              </el-table-column>
              <el-table-column label="表格行号" width="100" fixed="left" align="center">
                <template #default="scope"><el-tag>{{ scope.row.row_label }}</el-tag></template>
              </el-table-column>  

              <!-- 信息列 循环展示 -->
              <el-table-column v-for="col in colList" :key="col" :min-width="errorColumnMinWidth">
                <template #header><el-tag>列{{ col }}</el-tag></template>
                <template #default="scope"><el-tag type="danger" v-if="scope.row.message_list[`${col}`]">{{ scope.row.message_list[`${col}`] }}</el-tag></template>
              </el-table-column>

              <!-- <el-table-column label="操作" min-width="150" >
                <template slot-scope="scope">
                  <span class="es-action-button primary" @click="onUpdateClick(scope.row.goods_id)">编辑</span>
                  <span class="es-action-button danger" @click="onDeleteClick(scope.row.goods_id)">删除</span>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <!-- 表格内容 END -->
        </div>


      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import BasePageMixin from '@/mixin/BasePageMixin.js'
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { UploadFilled } from '@element-plus/icons-vue'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'

export default {
  mixins: [ DataTableMixin ],
  name: "goods-upload-page",
  components: {
    UploadFilled,
  },
  props: {
  },
  data() {
    return {

      errorColumnMinWidth: 180,
      colList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'U', 'V', 'W'],

      uploadUrl: GlobalConfig.host + 'api/admin/goods/goods-upload/action',
      uploadUrl2: GlobalConfig.host + 'api/admin/goods/goods-upload/action-haikou',

      // 模板类型
      template_type: 'Haikou',

      // 默认设置Map
      templateSettingMap:{
        Guangzhou: {
          href: 'static/output_template/goods_guangzhou_2021011.xlsx', download: '商品导入模板1-2021011.xlsx', version: 'v1-2021011',
          uploadUrl: GlobalConfig.host + 'api/admin/goods/goods-upload/action',
        },
        Haikou: {
          href: '/static/output_template/goods_haikou_2021061.xlsx', download: '商品导入模板2-2021061.xlsx', version: 'v2-2021061',
          uploadUrl: GlobalConfig.host + 'api/admin/goods/goods-upload/action-haikou',
        },
      },


      isUploading: false,

      alert_title: '',
      alert_type: '',

      uploadResult: -1,


      shop_id: '',
      // 当前店铺id
      current_shop_id: '32201908210407394928150064e55678', // 32201908210407394928150064e55678

      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      goods_id: '',
      formModel: {
        goods_id: '',
        goods_name: '',
        merchant_id: '',
        shop_id: '',
        goods_sn: '',
        yz_item_id: '',
        is_multi_spec: 0,
      },
      // 多规格时，对应sku数组
      skus: [],

      // 拷贝规则
      cloneFields: [
        'goods_name', 'merchant_id', 'shop_id', 'goods_sn', 'yz_item_id',
        {key: 'is_multi_spec', default: 0},
      ],

      formModel2: {

      },


      uploadFiles: [],

    }
  },
  computed: {},
  watch: {},
  methods: {
    getTableHeightFix() {
      return 280
    },

    // ===================================================================================================

    // 点击添加
    // onAddClick(id) {
    //   this.$refs.updateView.showView({ editMode: "insert" })
    // },
    // 点击删除
    // onDeleteClick(id) {
    //   this.resolveDeleteConfirm().then(() => {
    //     apiDeleteStaffObject({
    //       staff_id: id,
    //     }).then((res) => {
    //       this.getPage()
    //       this.$message.success("操作成功")
    //     })
    //   }).catch(()=>{})
    // },



    // 店铺选择后触发
    onShopChanged(merchant_id, shop_id) {
      // console.log(merchant_id)
      // console.log(shop_id)
      this.formModel.merchant_id = merchant_id
      this.formModel.shop_id = shop_id
      // this.current_shop_id = shop_id
    },




    // 点击上传按钮 触发
    onUploadClick() {
      // console.log(this.$refs.uploadView); return;
      if (this.uploadFiles.length > 0) {
        this.isUploading = true
        this.uploadResult = -1
        this.epDataList = []
        this.$refs.uploadView.submit()
      } else {
        this.$message.error('请先选择需要上传的文件')
      }
    },

    // 当上传失败时 触发
    onUploadSuccess(response, file, fileList) {
      // console.log(response.data);
      this.uploadResult = response.data.error


      if (response.data.error == 1) {
        this.epDataList = response.data.messages
      } else {
        this.$message.success("操作成功")
      }
      this.isUploading = false
      this.uploadFiles = []
      // this.$message.success("操作成功")
    },

    // 当上传失败时 触发
    onUploadError(err, file, fileList) {
      this.isUploading = false
      this.uploadFiles = []
      this.$message.error('上传数据错误')
    },

    // ===================================================================================================


  },
  mounted() {
    this.$nextTick(() => {
      // if (this.$$auth.hasPermission('A0004')) {
      //   this.template_type = 'Guangzhou'
      // } else if (this.$$auth.hasPermission('A0005')) {
      //   this.template_type = 'Haikou'
      // }
    })
  }
}
</script>

<style scoped>
.m-file-wrap {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
}
.m-upload-button {
  margin-top: 20px;
  width: 360px;
  height: 42px;
}
</style>
