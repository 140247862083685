export const CategoryCodeModel = {
  _PairData: [
    { code: '1', name: '个护' },
    { code: '2', name: '美妆' },
    { code: '3', name: '母婴' },
    { code: '4', name: '数码' },
    { code: '5', name: '保健' },
    { code: '6', name: '服饰' },
    { code: '7', name: '食品' },
    { code: '8', name: '宠物' },
    { code: '9', name: 'OTC' },
    { code: '0', name: '其他' },
  ],
  getPairData() {
    return this._PairData
  },
  code2Name(code) {console.log(code)
    for(let val of this._PairData) { console.log(val); if (val.code == code) { return val.name; break } } return '-'
  },
}