// import EsBreadcrumb from '@/components/common/page/EsBreadcrumb'

export default {
  components: {
    // EsBreadcrumb,
  },
  //
  _esScreenHeight: 0, // 可视窗口-高度


  data() {
    return {
      esScreenHeight: this._esScreenHeight, // 可视窗口-高度
      esFixedTableHeight: document.documentElement.clientHeight - this.getTableHeightFix(),
      // 
    }
  },
  methods: {

    test() {
      // console.log(this.aaa)
    },
    _esSetTableHeight() {
      // this.tableHeight = document.documentElement.clientHeight - this.fitHeight;
      // console.log(document.documentElement.clientHeight)
      this._esScreenHeight = document.documentElement.clientHeight
      // console.log(this._esScreenHeight)
      this.esFixedTableHeight = this._esScreenHeight - this.getTableHeightFix()
      // console.log(this.esFixedTableHeight)
    },
    // 窗体大小改变触发事件
    _esOnWindowResize(event) {
      // this._setTableHeight();
      this._esSetTableHeight()
    },

    // ===============================================================================================

    // 获取table高度
    getTableHeight() {
      // return this._esScreenHeight - this.getTableHeightFix()
      return this.esScreenHeight - this.getTableHeightFix()
    },
    // 获取table高度修正
    getTableHeightFix() {
      return 0
    }
  },
  // created() {
  //   window.addEventListener('resize', this.getHeight);
  //   this.getHeight()
  // },
 
  // destroyed() {
  //   window.removeEventListener('resize', this.getHeight)
  // }
  mounted() {
    this._esOnWindowResize()
  },
  created() {
    window.addEventListener('resize', this._esOnWindowResize)
    // this.getHeight()
  },
 
  destroyed() {
    window.removeEventListener('resize', this._esOnWindowResize)
  }
}