<template>
  <el-dialog v-model="viewVisible" width="1000px" :show-close="false" class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">添加分销货品</span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <div>
      <el-card :body-style="{padding:'0px', 'padding': '16px'}">
        <!-- 提示 START -->
        <!-- <el-alert type="info" show-icon :closable="false" title="货品导入" style="margin-bottom:18px;max-width:1000px;border:1px solid">
          <div>系统以货品编码作为识别标识</div>
        </el-alert> -->
        <!-- 提示 END -->

        <el-form ref="form1" label-width="175px">

          <!-- <div class="ep-form-region-title">分销商信息</div> -->
          <!-- <el-form-item label="分销商商户：" prop="fx_merchant_id">
            <el-select v-model="fx_merchant_id" filterable placeholder="请选择" :disabled="editMode=='update'" style="width:300px;" @change="onFxMerchantIdChange">
              <el-option v-for="item in fxMerchantsPairs" :key="item.merchant_id" :label="item.merchant_name" :value="item.merchant_id">
                <span class="ep-select-option-left">{{ item.merchant_name }}</span>
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="分销商：" prop="fx_shop_id">
            <el-select v-model="fx_shop_id" filterable placeholder="请选择" :disabled="editMode=='update'" style="width:300px;" @change="onFxShopIdChange">
              <el-option v-for="item in fxShopPairs" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                <span class="ep-select-option-left">{{ item.shop_name }}</span>
              </el-option>
            </el-select>
          </el-form-item> -->

          <!-- <el-divider /> -->

          <div class="ep-form-region-title">供应商信息</div>
          <el-form-item label="供应商：" prop="sup_shop_id">
            <el-select v-model="sup_shop_id" filterable placeholder="请选择" :disabled="editMode=='update'" style="width:300px;" @change="onSupShopIdChange">
              <el-option v-for="item in supMerchantShopPairs" :key="item.sup_shop_id" :label="item.sup_shop_name" :value="item.sup_shop_id">
                <span class="ep-select-option-left">{{ item.sup_shop_name }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="货品编码：" prop="goods_item_code">
            <div>
              <el-input v-model="goods_item_code" style="width:300px;"/>
              <el-button type="primary" @click="onGoodsSearchClick" style="margin-left:10px;">查找</el-button>
            </div>
          </el-form-item>
        </el-form>

        <el-divider />

        <div v-show="hasResult">
          <template v-if="resultCode == 0">
            <el-alert type="success" show-icon :closable="false" title="货品查找成功" style="margin-bottom:18px;max-width:1000px;border:1px solid">
              <div>{{ resultMessage }}</div>
            </el-alert>
          </template>
          <template v-else>
            <el-alert type="error" show-icon :closable="false" title="货品查找失败" style="margin-bottom:18px;max-width:1000px;border:1px solid">
              <div>{{ resultMessage }}</div>
            </el-alert>
          </template>
        </div>

      </el-card>

      
    </div>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :disabled="resultCode != 0"><el-icon><check/></el-icon>提交</el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><close/></el-icon>取消</el-button>

    </template>

  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Close, Check, UploadFilled } from '@element-plus/icons-vue'
import { apiV1GetSupMerchantShopPairs } from '@/api/fenxiao.js'
import { apiV1ActionSearchSupGoodsItem, apiV1ActionSelectSupGoodsItem } from '@/api/goods.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'

export default {
  name: 'sup-goods-item-upload-dialog',
  mixins: [ DataTableMixin ],
  components: {
    Close, Check, UploadFilled,
  },
  props: [],
  emits: ['submit'],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,
      //
      // 检验规则
      // formRules: {
      //   name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
      //   staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      // },
      // 拷贝规则
      // cloneFields: [
      //   'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
      //   {key: 'sex', default: 0},
      // ],

      fxMerchantsPairs: [], // 分销商户列表
      fxShopPairs: [], // 分销店铺列表
      fx_merchant_id: '',
      fx_shop_id: '',

      supMerchantShopPairs: [], // 供应店铺列表
      sup_merchant_id: '',
      sup_shop_id: '',
      goods_item_code: '',

      hasResult: false,
      resultCode: -1,
      resultMessage: '',
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode } = {}) {
      this.editMode = editMode || 'insert'
      this.viewVisible = true
    },
    // 当窗体打开时
    onViewOpen() {
      this.hasResult = false
      this.resultCode = -1
      this.resultMessage = ''
    },

    onGoodsSearchClick() {
      apiV1ActionSearchSupGoodsItem({
        sup_shop_id: this.sup_shop_id,
        goods_item_code: this.goods_item_code
      }).then((res) => {
        this.hasResult = true
        this.resultCode = res.data.code
        this.resultMessage = res.data.message
        // this.fxMerchantsPairs = res.data.data;
      })
    },

    // 提交触发 - 确认添加分销货品
    onSubmit() {
      apiV1ActionSelectSupGoodsItem({
        fx_shop_id: AuthUtils.getCurrentShopId(),
        sup_shop_id: this.sup_shop_id,
        goods_item_code: this.goods_item_code
      }).then((res) => {
        // console.log(res.data)
        if (res.data.code == 0) {
          this.$emit('submit')
          this.$message.success(res.data.message)
          this.viewVisible = false
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    
    // ===================================================================================================

    // 当商户改变时 触发
    onFxMerchantIdChange(newValue) {
      this.fxShopPairs = []
      this.fx_shop_id = ''
      this.supMerchantShopPairs = []
      this.sup_shop_id = ''
      this.getFxShopDataByMerchantId()
    },
    // 当店铺改变时 触发
    onFxShopIdChange(newValue) {
      this.getSupShopDataByMerchantId()
    },


    // 当店铺改变时 触发
    onSupShopIdChange(newValue) {
      // this.$refs.refGoodsSpecView.setShopId(this.formModel.shop_id)
    },


    // ===================================================================================================


    // 获取商户结构对数据
    // getFxMerchantsPairData() {
    //   if (this.fxMerchantsPairs.length == 0) {
    //     apiV1GetMerchantsPairs().then((res) => {
    //       this.fxMerchantsPairs = res.data.data;
    //     })
    //   }
    // },
    // 根据商户ID获取店铺pair
    // getFxShopDataByMerchantId() {
    //   // if (this.fxShopPairs.length == 0) {
    //     apiV1GetShopPairs({merchant_id: this.fx_merchant_id}).then((res) => {
    //       this.fxShopPairs = res.data.data;
    //     })
    //   // }
    // },


    // 根据分销商户ID获取供应店铺pair
    getSupShopDataByMerchantId() {
      console.log(AuthUtils.getCurrentShopId())
      // if (this.supMerchantShopPairs.length == 0) {
        apiV1GetSupMerchantShopPairs({fx_shop_id: AuthUtils.getCurrentShopId()}).then((res) => {
          this.supMerchantShopPairs = res.data.data;
        })
      // }
    },


    // ===================================================================================================

    

  },
  mounted() {
    this.$nextTick(() => {
      // this.getFxMerchantsPairData()
      this.getSupShopDataByMerchantId()
    })
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
