
<template>
  <div style="padding:20px;"><el-card>
  <!-- 表格 START -->
  <div class="es-table-content">
    <el-table ref="esDataTable" fit stripe border highlight-current-row :header-cell-style="{background:'#79bbff',color:'#fefefe'}" :data="skus">
      <!-- <el-table-column type="selection" width="50" /> -->
      <el-table-column label="完整规格名称" min-width="250" ><!-- fixed -->
        <template #default="scope">
          {{ scope.row.sku_name }}
          <div v-if="scope.row.declaration_tag_name != ''"><el-tag type="success">{{ scope.row.declaration_tag_name }}</el-tag></div>
          <div class="ep-cell-subtitle">{{ scope.row.goods_id }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="goods_sku" label="规格编码" min-width="100" />
      <!-- <el-table-column prop="yz_sku_id" label="有赞商品SKU" min-width="100" /> -->
      <!-- <el-table-column label="店铺编码" min-width="150" >
        <template #default="scope">
          <div>店铺商品编码：{{ scope.row.outer_item_code }}</div>
          <div>店铺规格编码：{{ scope.row.outer_sku_code }}</div>
        </template>
      </el-table-column> -->

      <el-table-column prop="outer_item_code" label="店铺商品编码" min-width="100" />
      <el-table-column prop="outer_sku_code" label="店铺规格编码" min-width="100" />

      <el-table-column prop="origin_code_name" label="进口地" min-width="100" />
      <el-table-column prop="unit_code_name" label="单位" min-width="100" />

      <!-- <el-table-column label="重量" min-width="100" >
        <template #default="scope">
          <div>毛重：{{ scope.row.gross_weight }}</div>
          <div>净重：{{ scope.row.net_weight }}</div>
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="备案信息" min-width="250" >
        <template #default="scope">
          <div>海关备案号：{{ scope.row.cus_goods_no }}</div>
          <div>国检备案号：{{ scope.row.ciq_goods_no }}</div>
          <div>海关HSCode：{{ scope.row.hs_code }}</div>
        </template>
      </el-table-column> -->

      <el-table-column prop="goods_spec" label="商品规格" min-width="100" />
      <el-table-column prop="brand_name" label="品牌" min-width="100" />
      
      <!-- <el-table-column label="操作" min-width="150" >
        <template slot-scope="scope">
          <span class="es-action-button primary" @click="onDetailClick(scope.row.staff_id)">查看</span>
          <span class="es-action-button primary" @click="onUpdateClick(scope.row)">编辑</span>
          <span class="es-action-button danger" @click="onDeleteSkuClick(scope.row)">删除</span>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
  <!-- 表格 END -->
  </el-card></div>
</template>

<script>
export default {
  name: "es-pagination",
  props: [
    'skus'
  ],
  data() {
    return {
      // skus: [],
    };
  },
  methods: {
    // 点击搜索按钮
    onSearchClick() {
      this.$emit('search', {})
    },
    // 获取搜索filter
    getSearchFilter() {
      // let searchFilter = {}
      // if (this.searchKey.trim() != '' && this.searchContent.trim() != '') {
      //   searchFilter = {[this.searchKey]: this.searchContent}
      // }
      // return searchFilter
    },
  },
  mounted() {
    // console.log(this)
  },
  watch: {
  }
};
</script>

<style scoped>
</style>
