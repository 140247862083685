<template>
  <el-dialog v-model="viewVisible" width="1000px" :show-close="false" custom-class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">添加组合商品</span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <div>
      <el-card :body-style="{padding:'0px', 'padding': '16px'}">

        <el-form ref="form1" :model="formModel" label-width="175px" v-loading="isLoading" status-icon :rules="formRules">

          <div class="ep-form-region-title">基本信息</div>
          <!-- <el-form-item label="所属商户：" prop="merchant_id">
            <el-select v-model="merchant_id" filterable placeholder="请选择" :disabled="editMode=='update'" style="width:300px;" @change="onMerchantIdChange">
              <el-option v-for="item in merchantsPairs" :key="item.merchant_id" :label="item.merchant_name" :value="item.merchant_id">
                <span class="ep-select-option-left">{{ item.merchant_name }}</span>
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="所属店铺：" prop="shop_id">
            <el-select v-model="shop_id" filterable placeholder="请选择" :disabled="editMode=='update'" style="width:300px;" @change="onShopIdChange">
              <el-option v-for="item in shopPairs" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                <span class="ep-select-option-left">{{ item.shop_name }}</span>
              </el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="商品名称：" prop="combine_goods_name">
            <el-input v-model="formModel.combine_goods_name" placeholder="请输入商品名称" style="width:500px;" />
            <!-- <div v-if="editMode=='update'" class="es-cell-subtitle">原商品名称：{{ backupFormModel.combine_goods_name }}</div> -->
          </el-form-item>
          <el-form-item label="商品编码：" prop="combine_goods_code">
            <el-input v-model="formModel.combine_goods_code" placeholder="请输入商品编码" style="width:500px;" />
            <!-- <div v-if="editMode=='update'" class="es-cell-subtitle">原商品编码：{{ backupFormModel.combine_goods_code }}</div> -->
          </el-form-item>
          <el-form-item label="店铺商品编码：" prop="outer_item_code">
            <el-input v-model="formModel.outer_item_code" placeholder="请输入店铺商品编码" style="width:500px;" />
          </el-form-item>
          <el-form-item label="店铺规格编码：" prop="outer_sku_code">
            <el-input v-model="formModel.outer_sku_code" placeholder="请输入规格编码" style="width:500px;" />
          </el-form-item>

          <el-divider />

          <div class="ep-form-region-title">子商品信息</div>

          <el-form-item label="待添加商品规格编码：" placeholder="请输入商品规格编码" prop="goods_item_code">
            <div>
              <el-input v-model="goods_sku" style="width:300px;"/>
              <el-button type="primary" @click="onSearchGoodsClick" style="margin-left:10px;" :disabled="this.goods_sku == ''">添加</el-button>
            </div>
          </el-form-item>

          <div v-show="hasResult">
            <template v-if="resultCode != 0">
              <el-alert type="error" show-icon :closable="true" title="子商品添加失败" style="margin-bottom:18px;max-width:1000px;border:1px solid">
                <div>{{ resultMessage }}</div>
              </el-alert>
            </template>
          </div>

        </el-form>

        <el-divider />

        <el-table ref="epDataTable" fit stripe border highlight-current-row :header-cell-style="{background:'#79bbff',color:'#fefefe'}" :data="subGoodsItemList">
          <!-- <el-table-column type="selection" width="50" /> -->
          <el-table-column label="商品名称" min-width="250" ><!-- fixed -->
            <template #default="scope">
              {{ scope.row.sub_goods_item_name }}
              <!-- <div v-if="scope.row.declaration_tag_name != ''"><el-tag type="success">{{ scope.row.declaration_tag_name }}</el-tag></div> -->
              <!-- <div class="ep-cell-subtitle">{{ scope.row.sub_goods_item_code }}</div> -->
            </template>
          </el-table-column>

          <el-table-column label="商品规格编码" min-width="150" ><!-- fixed -->
            <template #default="scope">
              {{ scope.row.sub_goods_item_code }}
            </template>
          </el-table-column>

          <el-table-column label="商品数量" min-width="100" >
            <template #default="scope">
              <!-- <el-form-item :prop="scope.row.sub_goods_item_id"> -->
                <el-input v-model="scope.row.sub_goods_item_qty" />
              <!-- </el-form-item> -->
            </template>
          </el-table-column>

          <el-table-column label="商品单价（元）" min-width="100" >
            <template #default="scope">
              <!-- <el-form-item :prop="scope.row.sub_goods_item_id"> -->
                <el-input v-model="scope.row.sub_goods_item_price" />
              <!-- </el-form-item> -->
            </template>
          </el-table-column>

          <el-table-column label="操作" min-width="100" >
            <template #default="scope">
              <span class="ep-action-button danger" @click="onDeleteSkuClick(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>

    </div>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :disabled="resultCode != 0"><el-icon><check/></el-icon>提交</el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><close/></el-icon>取消</el-button>

    </template>

  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Close, Check, UploadFilled } from '@element-plus/icons-vue'
// import { apiV1GetMerchantsPairs, apiV1GetShopPairs } from '@/api/merchant.js'
import { apiV1GetCombineGoodsObject, apiV1InsertCombineGoods, apiV1UpdateCombineGoods, apiV1ActionSearchSku } from '@/api/goods.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'

export default {
  name: 'sup-goods-item-upload-dialog',
  mixins: [ DataTableMixin ],
  components: {
    Close, Check, UploadFilled,
  },
  props: [],
  emits: ['submit'],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,
      //
      combine_goods_id: '',
      formModel: {
        merchant_id: '',
        shop_id: '',
        combine_goods_name: '',
        combine_goods_code: '',
        // outer_combine_goods_code: '',
        outer_item_code: '',
        outer_sku_code: '',
      },
      //
      // 检验规则
      formRules: {
        combine_goods_name: [{required: true, message: '请输入商品名称', trigger: 'blur'}],
        combine_goods_code: [{required: true, message: '请输入商品编码', trigger: 'blur'}],
        // outer_combine_goods_code: [{required: true, message: '请输入店铺商品编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'combine_goods_name', 'combine_goods_code', 'outer_item_code', 'outer_sku_code',
        // 'outer_combine_goods_code',
        //  'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        // {key: 'sex', default: 0},
      ],

      backupFormModel: {},

      merchantsPairs: [], // 分销商户列表
      shopPairs: [], // 分销店铺列表
      merchant_id: '',
      shop_id: '',

      goods_sku: '', // 待添加商品SKU

      subGoodsItemList: [],

      hasResult: false,
      resultCode: -1,
      resultMessage: '',
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.combine_goods_id = id
      this.viewVisible = true
    },
    // 当窗体打开时
    onViewOpen() {
      this.subGoodsItemList = []
      this.goods_sku = ''
      this._SetSubGoodsOperMessage()
      if (this.editMode === 'update') {
        const loadingInstance = this.$loading({})
        apiV1GetCombineGoodsObject({
          combine_goods_id: this.combine_goods_id,
        }).then((res) => {
          this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)
          // this.merchant_id = res.data.data.merchant_id
          // this.$nextTick(() => {
          //   this.getShopDataByMerchantId()
          //   this.shop_id = res.data.data.shop_id
          // })
          this.subGoodsItemList = res.data.data.subGoodsItemList
          loadingInstance.close()
        })
      } else {
        this.formModel = this.$$utils.cloneObject({}, this.cloneFields)
        this.combine_goods_id = ''
        // this.merchant_id = ''
        // this.shop_id = ''
        
      }
    },
    // 点击触发 - 搜索并添加SKU项
    onSearchGoodsClick() {
      this._SetSubGoodsOperMessage()
      /* 子商品数量上限 - 20 */
      if (this.subGoodsItemList.length == 20) { this._SetSubGoodsOperMessage(true, -1, '子商品项过多'); return }
      if (this.goods_sku.trim() == '') { this._SetSubGoodsOperMessage(true, -1, '请输入有效的子商品规格编码'); return }
      var inGoodsSku = this.goods_sku.trim()
      var skuExist = false
      this.subGoodsItemList.forEach((value) => { if (value.sub_goods_item_code == inGoodsSku) { skuExist = true } })
      if (skuExist == true) { this.goods_sku = ''; return }
      // if (this.shop_id == '') { this._SetSubGoodsOperMessage(true, -1, '请先选择所属商户店铺'); return }

      apiV1ActionSearchSku({
        shop_id: AuthUtils.getCurrentShopId(),
        goods_sku: inGoodsSku
      }).then((res) => {
        this._SetSubGoodsOperMessage(true, res.data.code, res.data.message)

        if (this.resultCode == 0) {
          this.subGoodsItemList.push({
            sub_goods_item_id: res.data.data.sku_id,
            sub_goods_item_name: res.data.data.sku_name,
            sub_goods_item_code: res.data.data.goods_sku,
            sub_goods_item_qty: 0,
            sub_goods_item_price: 0,
          })
          this.goods_sku = ''
        }
      })
    },
    // 点击删除SKU触发
    onDeleteSkuClick(row) {
      this.resolveDeleteConfirm().then(() => {
        var targetIndex = -1
        this.subGoodsItemList.forEach((value, index) => { if (value.sub_goods_item_id == row.sub_goods_item_id) { targetIndex = index } })
        this.subGoodsItemList.splice(targetIndex, 1)
      }).catch(()=>{})
    },


    // 提交触发 - 确认添加分销货品
    onSubmit() {
      this.$refs['form1'].validate((validResult) => {
        if (validResult === false) {
          this.$message.error('请正确填写表单数据'); return
        } 
        
        let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)
        formData.merchant_id = AuthUtils.getUserId()
        formData.shop_id = AuthUtils.getCurrentShopId()
        formData.subGoodsItemList = this.subGoodsItemList

        if (this.editMode === 'update') {
          formData.combine_goods_id = this.combine_goods_id
          apiV1UpdateCombineGoods(formData).then((res) => {
            if (res.data.code != 0) { this.$message.error(res.data.message); return } else { this.viewVisible = false; this.$emit('submit') }
          })
        } else {
          apiV1InsertCombineGoods(formData).then((res) => {
            this.viewVisible = false;
            this.$emit('submit')
          })
        }
      });

      // apiV1ActionSelectSupGoodsItem({
      //   shop_id: this.shop_id,
      //   sup_shop_id: this.sup_shop_id,
      //   goods_item_code: this.goods_item_code
      // }).then((res) => {
      //   // console.log(res.data)
      //   if (res.data.code == 0) {
      //     this.$emit('submit')
      //     this.$message.success(res.data.message)
      //     this.viewVisible = false
      //   } else {
      //     this.$message.error(res.data.message)
      //   }
      // })
    },
    
    // ===================================================================================================

    // 当商户改变时 触发
    // onMerchantIdChange(newValue) {
    //   this.shopPairs = []
    //   this.shop_id = ''
    //   // this.supMerchantShopPairs = []
    //   // this.sup_shop_id = ''
    //   this.getShopDataByMerchantId()
    // },
    // 当店铺改变时 触发
    // onShopIdChange(newValue) {
    //   // this.getShopDataByMerchantId()
    // },




    // ===================================================================================================


    // 获取商户结构对数据
    // getMerchantsPairData() {
    //   if (this.merchantsPairs.length == 0) {
    //     apiV1GetMerchantsPairs().then((res) => {
    //       this.merchantsPairs = res.data.data;
    //     })
    //   }
    // },
    // 根据商户ID获取店铺pair
    // getShopDataByMerchantId() {
    //   // if (this.shopPairs.length == 0) {
    //     apiV1GetShopPairs({merchant_id: this.merchant_id}).then((res) => {
    //       this.shopPairs = res.data.data;
    //     })
    //   // }
    // },

    // ===================================================================================================

    // 设置子商品操作反馈信息。默认无参数重置信息
    _SetSubGoodsOperMessage(result = false, code = 0, message = '') {
      this.hasResult = result; this.resultCode = code; this.resultMessage = message
    }

  },
  mounted() {
    this.$nextTick(() => {
      // this.getMerchantsPairData()
    })
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
