<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>商品</el-breadcrumb-item><el-breadcrumb-item>商品</el-breadcrumb-item><el-breadcrumb-item>商品编辑</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter">
        <!-- <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon>提交</el-button> -->
        <!-- <el-button type="default" @click="$router.push({ path: `/v1/goods/goods/list` })"><el-icon><close/></el-icon>取消</el-button> -->
      </div>
      <!-- <div class="ep-page-header-toolbar"></div> -->
    </el-header>
    <el-container style="padding:0 20px;">
      <el-aside style="padding: 0;" width="450px">
        <div class="ep-form-panel">
          <GoodsInfoView ref="refGoodsInfoView" />
        </div>
      </el-aside>
      <el-main class="ep-page-main" style="margin-left:20px;padding:10px 10px;background:white;">
        <GoodsSpecView ref="refGoodsSpecView" @goods-skus-change="onGoodsSkusChange" />
      </el-main>

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon>提交</el-button>
          <el-button type="default" @click="$router.push({ path: `/v1/goods/goods/list` })"><el-icon><close/></el-icon>取消</el-button>
        </div>
        <div class="ep-float-footer-pager">
        </div>
      </div>
      <!-- 数据页面固定footer END -->
    </el-container>
  </el-container>
</template>

<script>
import { Check, Close } from '@element-plus/icons-vue'
import GoodsInfoView from '@/views/v1/goods/goods/edit/GoodsInfoView'
import GoodsSpecView from '@/views/v1/goods/goods/edit/GoodsSpecView'

// import { apiGetCampusPairs } from '@/apis/school.js'
import { apiGetGoodsObject, apiInsertGoods, apiUpdateGoods } from '@/api/goods.js'

export default {
  name: "goods-edit-page",
  components: {
    Check, Close,
    GoodsInfoView,
    GoodsSpecView,
  },
  props: {
    pageMode: {
      type: String,
      default: 'insert'
    },
  },
  data() {
    return {
      editMode: 'insert',

      goods_id: '', // 当前商品goods_id
      mainGoodsObject: {}, // 完整商品信息对象
      backupGoodsObject: {}, // 备份完整商品信息对象

      // 对象关键字段
      mainIsMultiSpec: 0, // 商品单规格/多规格标志
      mainGoodsSkus: [], // 商品SKU数组

    }
  },
  computed: {},
  watch: {
    // mainIsMultiSpec: () => {
    //   // console.log(this.mainIsMultiSpec)
    // },
    // mainGoodsSkus: (newValue, oldValue) => {
    //   console.log(newValue)
    // },
  },
  methods: {

    // 获取完整商品信息对象
    getGoodsObject() {
      apiGetGoodsObject({
        goods_id: this.goods_id,
      }).then((res) => {
        this.backupGoodsObject = this.mainGoodsObject = res.data.data

        this._InitDataByGoodsObject(this.mainGoodsObject)
      })
    },

    // ===================================================================================================

    // 刷新商品数据
    _InitDataByGoodsObject(goodsObject) {
      goodsObject.goodsSkus.forEach((value) => {
        value.rndIndex = Math.floor(Math.random() * 10000)
      });

      this.mainIsMultiSpec = goodsObject.is_multi_spec
      this.mainGoodsSkus = goodsObject.goodsSkus

      // 记录当前编辑商品的 当前的商户ID
      this.$store.commit('setGoodsEditMerchantId', goodsObject.merchant_id)

      // this.$refs.refGoodsInfoPaneView.initView(goodsObject)
      // this.$refs.refGoodsSpecPaneView.initView({inIsMultiSpec: this.mainIsMultiSpec, inGoodsSkus: this.mainGoodsSkus})
      // this.$refs.refGoodsIdcodePaneView.initView({inGoodsSkus: this.mainGoodsSkus})

      this.$refs.refGoodsInfoView.initView({editMode: this.pageMode, goodsObject: goodsObject})
      this.$refs.refGoodsSpecView.initView({inIsMultiSpec: this.mainIsMultiSpec, inGoodsSkus: this.mainGoodsSkus})
    },


    // ===================================================================================================

    // 当商品SKU变化时 触发
    onGoodsSkusChange(inIsMultiSpec, inGoodsSkus) {
      // console.log(is_multi_spec)
      // console.log(inGoodsSkus)
      this.mainIsMultiSpec = inIsMultiSpec
      this.mainGoodsSkus = inGoodsSkus
      
      // this.$refs.refGoodsIdcodePaneView.initView({inGoodsSkus: this.mainGoodsSkus})
    },
    // 点击提交按钮 触发
    onSubmit() {
      let sendData = this.$refs.refGoodsInfoView.getData()
      // console.log(targetGoodsObject); return
      // var sendData = Object.assign(targetGoodsObject, this.mainGoodsSkus)
      sendData.goodsSkus = this.mainGoodsSkus
      // console.log(sendData); return

      if (this.mainGoodsSkus.length == 0) {
        this.$message.warning("请添加至少一个商品SKU"); return
      }

      if (this.pageMode == 'update') {
        sendData.goods_id = this.mainGoodsObject.goods_id

        apiUpdateGoods(sendData).then((res) => {
          // console.log(res.data)
          this.$message.success("操作成功")
        })
      } else {
        apiInsertGoods(sendData).then((res) => {
          // console.log(res.data)
          this.$message.success("操作成功")
        })
      }
    },
  },
  mounted() {
    // console.log(this.pageMode)
    this.goods_id = this.$route.params.id || ''

    this.$nextTick(() => {
      if (this.goods_id != '') {
        this.getGoodsObject()
      }
    })
  }
}
</script>

<style scoped>
</style>
