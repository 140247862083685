<template>
  <el-drawer v-model="viewVisible" direction="rtl" size="800px" custom-class="ep-drawer" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">
    <template #header><span>编辑：SKU</span></template>
    <div class="ep-drawer-wrap">

      <div class="ep-drawer-panel">
        <el-form ref="form1" :model="formModel" label-width="180px" v-loading="isLoading" status-icon :rules="formRules">
          <div class="ep-drawer-region-title">基础信息</div>
          <div class="ep-drawer-region-wrap">
            <el-form-item label="完整规格名称：" prop="sku_name">
              <el-input v-model="formModel.sku_name" />
            </el-form-item>
            <el-form-item label="规格编码：" prop="goods_sku">
              <el-input v-model="formModel.goods_sku" style="width:300px;" />
            </el-form-item>
          </div>
        </el-form>
      </div>  

      <div class="ep-drawer-panel">
        <el-form ref="form2" :model="formModel" label-width="180px" v-loading="isLoading" status-icon :rules="formRules">
          <div class="ep-drawer-region-title">电商平台识别信息</div>
          <div class="ep-drawer-region-wrap">
            <el-form-item label="店铺商品编码：" prop="outer_item_id">
              <el-input v-model="formModel.outer_item_id"/>
              <div class="ep-cell-subtitle">电商平台店铺内设置的商品编码</div>
            </el-form-item>
            <el-form-item label="店铺规格编码：" prop="outer_sku_id">
              <el-input v-model="formModel.outer_sku_id"/>
              <div class="ep-cell-subtitle">电商平台店铺内设置的规格编码</div>
            </el-form-item>
          </div>
        </el-form>
      </div>   
        
      <div class="ep-drawer-panel">  
        <el-form ref="form3" :model="formModel" label-width="180px" v-loading="isLoading" status-icon :rules="formRules">
          <div class="ep-drawer-region-title">申报信息</div>
          <div class="ep-drawer-region-wrap">
            <el-form-item label="海关备案号：" prop="cus_goods_no">
              <el-input v-model="formModel.cus_goods_no" style="width:300px;" />
              <div class="ep-cell-subtitle">如无该备案号，可用规格编码代替</div>
            </el-form-item>

            <el-form-item label="国检备案号：" prop="ciq_goods_no">
              <el-input v-model="formModel.ciq_goods_no" style="width:300px;" />
              <div class="ep-cell-subtitle">如无该备案号，可用规格编码代替</div>
            </el-form-item>

            <el-form-item label="账册序号：" prop="item_record_no">
              <el-input v-model="formModel.item_record_no" style="width:300px;" />
              <div class="ep-cell-subtitle">对应于总账册表体【序号】字段</div>
            </el-form-item>

            <el-form-item label="账册料号：" prop="item_no">
              <el-input v-model="formModel.item_no" style="width:300px;" />
              <div class="ep-cell-subtitle">对应于总账册表体【料号】字段</div>
            </el-form-item>

            <el-form-item label="海关HSCode：" prop="hs_code">
              <el-input v-model="formModel.hs_code" style="width:300px;" />
            </el-form-item>

            <el-form-item label="申报商品名称：" prop="item_name">
              <el-input v-model="formModel.item_name" />
            </el-form-item>

            <div class="ep-form-separation" />

            <el-form-item label="商品规格：" prop="goods_spec">
              <el-input v-model="formModel.goods_spec" style="width:300px;" />
            </el-form-item>

            <el-form-item label="品牌：" prop="brand_name">
              <el-input v-model="formModel.brand_name" style="width:300px;" />
            </el-form-item>

            <el-form-item label="原产地：" prop="origin_code">
              <el-select v-model="formModel.origin_code" placeholder="请选择" style="width:300px;">
                <el-option v-for="item in originPairs" :key="item.code" :label="item.name" :value="item.code">
                  <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="申报计量单位：" prop="unit_code">
              <el-select v-model="formModel.unit_code" placeholder="请选择" style="width:300px;">
                <el-option v-for="item in unitPairs" :key="item.code" :label="item.name" :value="item.code">
                  <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item label="法定计量单位/系数：" prop="unit_code1">
              <el-select v-model="formModel.unit_code1" placeholder="请选择" style="width:300px;">
                <el-option v-for="item in unitPairs" :key="item.code" :label="item.name" :value="item.code">
                  <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                </el-option>
              </el-select>
              <el-input v-model="formModel.unit_code1_qty_base" style="width:300px;" />
              <div class="ep-cell-subtitle">与申报计量单位数量转换时相乘的系数</div>
            </el-form-item>

            <el-form-item label="第二计量单位/系数：" prop="unit_code2">
              <el-select v-model="formModel.unit_code2" placeholder="请选择" style="width:300px;">
                <el-option v-for="item in unitPairs" :key="item.code" :label="item.name" :value="item.code">
                  <span class="ep-select-option-left">{{ item.name }}</span><span class="ep-select-option-right">{{ item.code }}</span>
                </el-option>
              </el-select>
              <el-input v-model="formModel.unit_code2_qty_base" style="width:300px;" />
              <div class="ep-cell-subtitle">与申报计量单位数量转换时相乘的系数</div>
            </el-form-item>

            <div class="ep-form-separation" />

            <el-form-item label="毛重：" prop="gross_weight">
              <!-- <el-input-number v-model="formModel.gross_weight" :precision="3" :controls="false"> -->
              <el-input v-model="formModel.gross_weight" style="width:300px;">
                <template #append>千克</template>
              </el-input>
            </el-form-item>
            <el-form-item label="净重：" prop="net_weight">
              <!-- <el-input-number v-model="formModel.gross_weight" :precision="3" :controls="false"> -->
              <el-input v-model="formModel.net_weight" style="width:300px;">
                <template #append>千克</template>
              </el-input>
            </el-form-item>
            <el-form-item label="税率：" prop="tax_rate">
              <!-- <el-input-number v-model="formModel.gross_weight" :precision="3" :controls="false"> -->
              <el-input v-model="formModel.tax_rate" style="width:300px;">
                <template #append>%</template>
              </el-input>
              <div class="ep-cell-subtitle">一般货物税率为9.100%，具体可查询商品税率</div>
            </el-form-item>

            <el-form-item label="条形码：" prop="barcode">
              <el-input v-model="formModel.barcode" style="width:300px;" />
            </el-form-item>
            <el-form-item label="成分描述：" prop="specification">
              <el-input v-model="formModel.specification" style="width:300px;" />
            </el-form-item>
            <el-form-item label="申报属性标签：" prop="refs_declaration_tag">
              <el-select ref="refDeclarationTag" v-model="formModel.refs_declaration_tag" placeholder="请选择" style="width:300px;">
                <el-option label="【无标签】" value="__NONE__" />
                <el-option v-for="item in systemDeclarationTagPairs" :key="item.tag_id" :label="item.tag_name" :value="item.tag_id">
                  <span class="ep-select-option-left">{{ item.tag_name }}</span><span class="ep-select-option-right">{{ item.tag_code }}</span>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="所属仓库：" prop="store_code">
              <el-select ref="refStore" v-model="formModel.store_code" placeholder="请选择" style="width:300px;">
                <el-option label="【默认】" value="__DEFAULT__" />
                <el-option v-for="item in storePairs" :key="item.store_id" :label="item.store_name" :value="item.store_id">
                  <span class="ep-select-option-left">{{ item.store_name }}</span><span class="ep-select-option-right">{{ item.store_code }}</span>
                </el-option>
              </el-select>
            </el-form-item>

          </div>
        </el-form>
      </div>


    </div>  

    <!-- </div> -->
    <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><close/></el-icon><span>取消</span></el-button>
    </template>
  </el-drawer>
</template>

<script>
import { Check, Close } from '@element-plus/icons-vue'
import { apiGetSystemDeclarationTagPairs } from '@/api/goods.js'
import { apiGetCustomsOriginPairs, apiGetCustomsUnitPairs } from '@/api/basedata.js'
import { apiV1GetStorePairs } from '@/api/merchant.js'
import AuthUtils from '@/utils/auth-utils.js'

export default {
  name: 'goods-sku-edit-view',
  components: {
    Check, Close,
  },
  emits: ['submit'],
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,
      //
      formModel: {
        rndIndex: 0, // 随机序号
        sku_id: '',
        sku_name: '',
        goods_sku: '',
        // 
        outer_item_id: '',
        outer_sku_id: '',
        //
        cus_goods_no: '',
        ciq_goods_no: '',
        item_record_no: '',
        item_no: '',
        hs_code: '',
        item_name: '',
        //
        goods_spec: '',
        brand_name: '',
        origin_code: '',
        unit_code: '',
        unit_code1: '',
        unit_code1_qty_base: '',
        unit_code2: '',
        unit_code2_qty_base: '',
        //
        gross_weight: '0',
        net_weight: '0',
        tax_rate: '',
        barcode: '',
        specification: '',
        refs_declaration_tag: '',
        store_code: '__DEFAULT__',
      },
      //
      originPairs: [],
      unitPairs: [],
      systemDeclarationTagPairs: [],
      storePairs:[],
      goodsSkuObj: {}, // SKU行对象

      // 检验规则
      formRules: {
        // name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        sku_name: [{required: true, message: '请输入完整规格名称', trigger: 'blur'}],
        goods_sku: [{required: true, message: '请输入规格编码', trigger: 'blur'}],
        //
        // outer_item_id: [{required: true, message: '请输入店铺商品编码', trigger: 'blur'}],
        // outer_sku_id: [{required: true, message: '请输入店铺规格编码', trigger: 'blur'}],
        //
        // item_record_no: [{required: true, message: '请输入企业商品货号', trigger: 'blur'}],
        // item_no: [{required: true, message: '请输入账册备案料号', trigger: 'blur'}],
        // hs_code: [{required: true, message: '请输入海关HSCode', trigger: 'blur'}],
        // item_name: [{required: true, message: '请输入申报商品名称', trigger: 'blur'}],
        //
        // goods_spec: [{required: true, message: '请输入商品规格', trigger: 'blur'}],
        // brand_name: [{required: true, message: '请输入品牌', trigger: 'blur'}],
        // origin_code: [{required: true, message: '请选择原产地', trigger: 'blur'}],
        // unit_code: [{required: true, message: '请选择申报计量单位', trigger: 'blur'}],
        // unit_code1: [{required: true, message: '请选择法定计量单位', trigger: 'blur'}],
        // unit_code1_qty_base: [{required: true, message: '请输入法定计量单位系数', trigger: 'blur'}],

        // gross_weight: [{required: true, message: '请输入毛重', trigger: 'blur'}],
        // net_weight: [{required: true, message: '请输入净重', trigger: 'blur'}],
        // tax_rate: [{required: true, message: '请输入税率', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'sku_name', 'goods_sku', 'outer_item_id', 'outer_sku_id', 
        'cus_goods_no', 'ciq_goods_no', 'item_record_no', 'item_no', 'hs_code', 'item_name',  
        'goods_spec', 'brand_name', 'origin_code', 'unit_code', 'unit_code1', 'unit_code1_qty_base', 'unit_code2', 'unit_code2_qty_base',
        'gross_weight', 'net_weight', 'barcode', 'specification', 'refs_declaration_tag',
        {key: 'rndIndex', default: 0}, {key: 'tax_rate', default: ''}, {key: 'store_code', default: '__DEFAULT__'},
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, goodsSkuObj } = {}) {
      this.editMode = editMode || 'insert'
      this.goodsSkuObj = goodsSkuObj

      this.viewVisible = true
    },
    
    // 当View打开时
    onViewOpen() {
      this.getCustomsOriginPairData()
      this.getCustomsUnitPairData()
      this.getSystemDeclarationTagPairData()
      this.getStorePairData()

      this.$nextTick(() => {
        if (this.editMode === 'update') {
          this.formModel = this.$$utils.cloneObject(this.goodsSkuObj, this.cloneFields)
          if (this.goodsSkuObj.sku_id) {
            this.formModel.sku_id = this.goodsSkuObj.sku_id

            if (this.formModel.refs_declaration_tag == '__NONE__' || this.formModel.refs_declaration_tag == null || this.formModel.refs_declaration_tag == '') {
              this.formModel.refs_declaration_tag = ''
            } else if (this.formModel.refs_declaration_tag != null) {
              this.formModel.refs_declaration_tag = parseInt(this.formModel.refs_declaration_tag)
            } else {
              this.formModel.refs_declaration_tag = ''
            }

            if (this.formModel.store_code != '__DEFAULT__') {
              this.formModel.store_code = parseInt(this.formModel.store_code)
            }
          }
        } else {
          this.formModel = this.$$utils.cloneObject({}, this.cloneFields)
        }
      })

      
    },
    // 点击提交按钮
    onSubmit() {
      this.$refs['form1'].validate((validResult) => {
        if (validResult === false) {
          this.$message.error('请正确填写【基础信息】'); return
        }

        this.$refs['form2'].validate((validResult2) => {
          if (validResult2 === false) {
            this.$message.error('请正确填写【电商平台识别信息】'); return
          }

          this.$refs['form3'].validate((validResult3) => {
            if (validResult3 === false) {
              this.$message.error('请正确填写【申报信息】'); return
            }

            let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)

            formData.origin_code_name = this.code2Name(this.originPairs, this.formModel.origin_code)
            formData.unit_code_name = this.code2Name(this.unitPairs, this.formModel.unit_code)

            formData.declaration_tag_name = this.$refs.refDeclarationTag.selectedLabel
            if (formData.declaration_tag_name == '【无标签】') {
              formData.declaration_tag_name = ''
            }

            if (this.editMode === 'update') {
              formData.rndIndex = this.formModel.rndIndex
              formData.sku_id = this.formModel.sku_id
            } else {
              formData.rndIndex = Math.floor(Math.random() * 10000)
            }

            // 触发回调事件，传递SKU行对象
            this.$emit('submit', this.editMode, formData)
            this.viewVisible = false;

          })
        })


      });
    },

    // ===================================================================================================

    // 获取进口地pair
    getCustomsOriginPairData() {
      if (this.originPairs.length == 0) {
        apiGetCustomsOriginPairs().then((res) => {
          this.originPairs = res.data.data;
        })
      }
    },
    // 获取单位pair
    getCustomsUnitPairData() {
      if (this.unitPairs.length == 0) {
        apiGetCustomsUnitPairs().then((res) => {
          this.unitPairs = res.data.data;
        })
      }
    },
    // 获取商品申报属性标签pair
    getSystemDeclarationTagPairData() {
      if (this.systemDeclarationTagPairs.length == 0) {
        apiGetSystemDeclarationTagPairs().then((res) => {
          this.systemDeclarationTagPairs = res.data.data;
        })
      }
    },

    code2Name(dataArray, key) {
      var name = ''
      dataArray.forEach((value) => {
        if (value.code == key) {
          name = value.name
        }
      });
      return name
    },

    // 获取店铺仓库列表
    getStorePairData() {
      if (this.storePairs.length == 0) {
        apiV1GetStorePairs({shop_id: AuthUtils.getCurrentShopId()}).then((res) => {
          this.storePairs = res.data.data;
        })
      }
    },

  },
  mounted() {
  }
};
</script>

<style scoped>
.el-form {
  width: 560px;
  margin-left: 0;
  margin-right: auto;
}
</style>
