<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>商品</el-breadcrumb-item><el-breadcrumb-item>商品管理</el-breadcrumb-item><el-breadcrumb-item>组合商品列表</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <el-button type="primary" @click="onAddClick">添加组合商品</el-button>
        <!-- <el-button type="primary" @click="onExportCurrentShopGoodsClick">导出所有商品</el-button> -->
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <CombineGoodsEditDialog ref="refCombineGoodsEditDialog" @submit="onEditSubmit" />
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <CombineGoodsSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <!-- <el-tab-pane label="未审核" name="0"></el-tab-pane> -->
          <!-- <el-tab-pane label="已审核" name="1"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            :row-class-name="funRowClassName" lazy>
            <!-- :height="esFixedTableHeight"  -->
            <!--  @selection-change="onTableSelectionChange" row-key="entity_id" :tree-props="{ children: 'children', hasChildren: 'has_children' }" -->

            <el-table-column type="selection" width="50" />

            <el-table-column type="expand">
              <template #default="scope">
                <CombineGoodsExpandDetailView :skus="scope.row.subGoodsItemList"/>
              </template>
            </el-table-column>

            <el-table-column label="组合商品名称" min-width="200" ><!-- fixed -->
              <template #default="scope">
                {{ scope.row.combine_goods_name }}
                <div class="ep-cell-subtitle">{{ scope.row.combine_goods_id }}</div>
              </template>
            </el-table-column>

            <el-table-column label="所属商户/店铺" min-width="150">
              <template #default="scope">
                <span class="ep-overflow-text">{{ scope.row.shop_name }}</span>
                <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.merchant_name }}</div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="商品状态" min-width="150" >
              <template #default="scope">
                <template v-if="scope.row.goods_state == 0"><el-tag type="info" effect="light">下架</el-tag></template>
                <template v-else-if="scope.row.goods_state == 1"><el-tag type="success" effect="light">上架</el-tag></template>
              </template>
            </el-table-column> -->

            <el-table-column prop="combine_goods_code" label="商品编码" min-width="100" />

            <!-- <el-table-column prop="outer_combine_goods_code" label="店铺商品编码" min-width="100" /> -->
            <el-table-column prop="outer_item_code" label="店铺商品编码" min-width="100" />
            <el-table-column prop="outer_sku_code" label="店铺规格编码" min-width="100" />

            <el-table-column label="创建时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.created_at)"></span>
              </template>
            </el-table-column>

            <el-table-column label="操作" min-width="150" fixed="right">
              <template #default="scope">
                <span class="ep-action-button primary" @click="onUpdateClick(scope.row.combine_goods_id)">编辑</span>
                <!-- <span class="ep-action-line"/> -->
                <!-- <span class="ep-action-button danger" @click="onDeleteClick(scope.row.combine_goods_id)">删除</span> -->
              </template>
            </el-table-column>
          </el-table>

        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCheckClick">审核</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCancelClick">作废</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onUnancelClick">恢复</el-button> -->
          <!-- <el-button v-permission="['A0002']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongMaskClick">导出订单</el-button> -->
          <!-- <el-button v-permission="['A0003']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongClick">导出完整订单</el-button> -->

          <!-- <el-dropdown split-button type="primary" style="margin-left:12px;" @click="onExportHainanCEB311Click">
            <span>导出：CEB格式</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="onExportYunsutongClick">导出：云速通</el-dropdown-item>
                <el-dropdown-item @click="onExportYunsutongMaskClick">导出：云速通-掩码</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
          
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->
    </el-main>

  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown } from '@element-plus/icons-vue'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import CombineGoodsSearchPanel from '@/views/v1/goods/combine_goods/list/CombineGoodsSearchPanel'
import CombineGoodsExpandDetailView from '@/views/v1/goods/combine_goods/list/CombineGoodsExpandDetailView'
import CombineGoodsEditDialog from '@/views/v1/goods/combine_goods/list/CombineGoodsEditDialog'
import { apiV1GetCombineGoodsPage, apiActionCatchShopGoods, apiDeleteGoods } from '@/api/goods.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'

// import { Test1 } from '@/pages/test/Test1'

export default {
  mixins: [ DataTableMixin ],
  name: "goods-list-page",
  components: {
    Edit,
    // Search,
    // Test1,
    SourceCodeTag,
    CombineGoodsSearchPanel,
    CombineGoodsExpandDetailView,
    CombineGoodsEditDialog,
  },
  props: [],
  data() {
    return {
      shop_id: '',
      current_merchant_id: '',
      current_shop_id: '', // 32201908210407394928150064e55678
      //
      activeName: 'first',
      epPageSize: 20,
      dataTableFilter: '__ALL__',
      //
    }
  },
  computed: {},
  methods: {
    getFilter() {
      let data = {}
      if (AuthUtils.getCurrentShopId()) {
        data = {shop_id: AuthUtils.getCurrentShopId() }
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true

      apiV1GetCombineGoodsPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
    },

    // ===================================================================================================

    // 点击 添加
    onAddClick() {
      this.$refs.refCombineGoodsEditDialog.showView({ editMode: "insert" })
    },
    // // 点击查看
    // onDetailClick(id) {
    //   this.$router.push({ name: 'MerchantsDetailPage', params: { id: id }})
    // },
    // 点击修改
    onUpdateClick(id) {
      this.$refs.refCombineGoodsEditDialog.showView({ editMode: "update", id: id })
    },
    // 点击删除
    onDeleteClick(id) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiDeleteMerchants({
      //     merchant_id: id,
      //   }).then((res) => {
      //     this.getPage()
      //     this.$message.success("操作成功")
      //   })
      // }).catch(()=>{})
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      this.$message.success("操作成功")
    },


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },

    // 更多按钮触发
    onMoreButtonClick(commandObj) {
      // console.log(commandObj)
      switch (commandObj.index) {
        case 'redeclare':
          this.onDeclareClick([commandObj.id], 1)
          break;
      }
    },

    // 添加分销货品
    onCombineGoodsEditClick() {
      this.$refs.refCombineGoodsEditDialog.showView({ editMode: "insert" })
    },



    bbb(row, column, rowIndex, columnIndex) {
      console.log(row)
      return "background-color:red"
    },

    load(row, treeNode, resolve) {
      console.log(row)
      console.log(treeNode)
      console.log(resolve)
    },

    // 点击 导出当前店铺商品
    onExportCurrentShopGoodsClick() {
      var url = `${GlobalConfig.host}api/admin/goods/goods/action/export-shop-goods?shop_id=` + AuthUtils.getCurrentShopId()
      window.open(url) 
    },

    // ===================================================================================================

    // 实体行样式
    funRowClassName({  row,  rowIndex,}) {
      if (row.entity_type === 'merchant') {
        return 'jxgocs-merchant-row'
      } else if (row.entity_type === 'shop') {
        return 'jxgocs-shop-row'
      }
      return ''
    }


    // 获取校区pair
    // getCampusPairData() {
    //   apiGetCampusPairs().then((res) => {
    //     this.campusPairs = res.data.data
    //   })
    // },

  },
  watch: {},
  mounted() {
    this.$$emitter.on('current-shop-changed', (shop_id) => {
      this.getPage()
    })

    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
</style>
