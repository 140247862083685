<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>商品</el-breadcrumb-item><el-breadcrumb-item>商品管理</el-breadcrumb-item><el-breadcrumb-item>云仓货品库存</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onSyncQuantityClick">货品库存同步</el-button> -->
        <!-- <el-button type="primary" @click="onBindGoodsClick">绑定商品</el-button> -->
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <!-- <SupGoodsItemQuantityUploadDialog ref="refSupGoodsItemQuantityUploadDialog" @submit="onEditSubmit" /> -->
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <CainiaoGoodsSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <!-- <el-tab-pane label="上架中" name="1"></el-tab-pane> -->
          <!-- <el-tab-pane label="下架中" name="0"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            @selection-change="onTableSelectionChange"
            :row-class-name="funRowClassName" lazy>
            <!-- :height="esFixedTableHeight"  -->
            <!--  @selection-change="onTableSelectionChange" row-key="entity_id" :tree-props="{ children: 'children', hasChildren: 'has_children' }" -->

            <el-table-column type="selection" width="50" />

            <!-- <el-table-column type="expand">
              <template #default="scope">
                <GoodsExpandDetailView :skus="scope.row.skus"/>
              </template>
            </el-table-column> -->
            
            <el-table-column label="货品主图" width="100" >
              <template #default="scope">
                <el-image class="m-main-image" :src="checkImageUrl(scope.row.main_image_url)" fit="contain">
                  <template #error><div class="image-slot"><el-icon><IconPicture/></el-icon></div></template>
                </el-image>
              </template>
            </el-table-column>

            <el-table-column label="商品名称" min-width="250" ><!-- fixed -->
              <template #default="scope">
                {{ scope.row.sku_name }}
                <div class="ep-cell-subtitle">{{ scope.row.sku_id }}</div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="所属商户/店铺" min-width="150">
              <template #default="scope">
                <el-popover placement="bottom" :width="250" trigger="click">
                  <template #reference>
                    <span class="ep-overflow-text"><SourceCodeTag :value="scope.row.shop_source_code" style="margin-right:4px;" />{{ scope.row.shop_name }}</span>
                  </template>
                  <div>
                    <div><SourceCodeTag :value="scope.row.shop_source_code" style="margin-right:4px;" />{{ scope.row.shop_name }}</div>
                    <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.merchant_name }}</div>
                  </div>
                </el-popover>
              </template>
            </el-table-column> -->

            <!-- <el-table-column label="商品状态" min-width="150" >
              <template #default="scope">
                <template v-if="scope.row.goods_state == 0"><el-tag type="info" effect="light">下架</el-tag></template>
                <template v-else-if="scope.row.goods_state == 1"><el-tag type="success" effect="light">上架</el-tag></template>
              </template>
            </el-table-column> -->

            <el-table-column label="创建时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.created_at)"></span>
              </template>
            </el-table-column>

            <el-table-column prop="goods_sn" label="商品编码" min-width="100" />
            <el-table-column prop="goods_sku" label="规格编码" min-width="100" />
            <el-table-column prop="kp_itemCode" label="物流编码" min-width="100" />
            <!-- <el-table-column prop="outer_item_code" label="店铺商品编码" min-width="100" /> -->
            <!-- <el-table-column prop="outer_sku_code" label="店铺规格编码" min-width="100" /> -->
            
            <!-- <el-table-column label="货品状态" min-width="100" fixed="right">
              <template #default="scope">
                <template v-if="scope.row.goods_item_state == 1"><el-tag type="success" effect="dark">上架中</el-tag></template>
                <template v-else><el-tag type="info" effect="dark">下架中</el-tag></template>
              </template>
            </el-table-column> -->

            <el-table-column prop="quantity" label="可用数量" min-width="100">
              <template #header>
                <el-popover placement="bottom" :width="250" trigger="click">
                  <template #reference>
                    <span class="ep-overflow-text">可用数量<el-icon style="margin-left:4px;"><QuestionFilled /></el-icon></span>
                  </template>
                  <div>
                    <div>可用数量 = 良品数量 - 锁定数量</div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="goodQuantity" label="良品数量" min-width="100" />
            <el-table-column prop="lockQuantity" label="锁定数量" min-width="100" />
            

            <!-- <el-table-column label="操作" min-width="150" fixed="right"> -->
              <!-- <template #default="scope"> -->
                <!-- <span class="ep-action-button primary" @click="onUpdateClick(scope.row.sup_goods_item_id)">编辑</span> -->
                <!-- <template v-if="scope.row.goods_item_state == 1">
                  <span class="ep-action-line"/><span class="ep-action-button primary" @click="onChangeStateClick(0, [scope.row.sup_goods_item_id])">下架</span>
                </template> -->
                <!-- <template v-if="scope.row.goods_item_state == 0">
                  <span class="ep-action-line"/><span class="ep-action-button primary" @click="onChangeStateClick(1, [scope.row.sup_goods_item_id])">上架</span>
                </template> -->
                <!-- <div v-if="scope.row.goods_item_state == 0"><span class="ep-action-button danger" @click="onDeleteClick(scope.row.sup_goods_item_id)">删除</span></div> -->
              <!-- </template> -->
            <!-- </el-table-column> -->
          </el-table>

        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onChangeStateClick(1)">上架</el-button> -->
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onChangeStateClick(0)">下架</el-button> -->
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onUnancelClick">恢复</el-button> -->
          <!-- <el-button v-permission="['A0002']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongMaskClick">导出订单</el-button> -->
          <!-- <el-button v-permission="['A0003']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongClick">导出完整订单</el-button> -->

          <!-- <el-dropdown split-button type="primary" style="margin-left:12px;" @click="onExportHainanCEB311Click">
            <span>导出：CEB格式</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="onExportYunsutongClick">导出：云速通</el-dropdown-item>
                <el-dropdown-item @click="onExportYunsutongMaskClick">导出：云速通-掩码</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
          
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->
    </el-main>

  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, Picture as IconPicture, QuestionFilled } from '@element-plus/icons-vue'
// import SupGoodsItemQuantityUploadDialog from '@/views/v1/goods/sup_goods_item_quantity/list/SupGoodsItemQuantityUploadDialog'
import CainiaoGoodsSearchPanel from '@/views/v1/goods/cainiao_goods_quantity/list/CainiaoGoodsSearchPanel'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1CainiaoGoodsQuantityPage, apiV1CainiaoGoodsQuantityActionSyncQuantity, apiV1CainiaoGoodsQuantityActionBindGoods } from '@/api/goods.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "sup-goods-item-list-page",
  components: {
    Edit, IconPicture, QuestionFilled,
    // SupGoodsItemQuantityUploadDialog,
    CainiaoGoodsSearchPanel,
    SourceCodeTag,
  },
  props: [],
  data() {
    return {
      shop_id: '',
      current_merchant_id: '',
      current_shop_id: '',
      //
      activeName: 'first',
      epPageSize: 20,
      dataTableFilter: '__ALL__',
      //
      main_image_url: '',
    }
  },
  computed: {},
  methods: {
    getFilter() {
      let data = {}
      if (AuthUtils.getCurrentShopId()) {
        data = { shop_id: AuthUtils.getCurrentShopId() }
      }
      if (this.dataTableFilter != '' && this.dataTableFilter != '__ALL__') {
        data.goods_item_state = this.dataTableFilter
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true

      apiV1CainiaoGoodsQuantityPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
    },

    // ===================================================================================================

    onTableSelectionChange(val) {
      this.epMultipleSelection = val
    },

    // 点击添加
    onAddClick() {
      let newUrl = this.$router.resolve({ path: `/v1/goods/goods/add` })
      window.open(newUrl.href, '_blank')
    },
    // 点击查看
    onDetailClick(id) {
      this.$router.push({ name: 'MerchantsDetailPage', params: { id: id }})
    },
    // 点击修改
    onUpdateClick(id) {
      let newUrl = this.$router.resolve({ path: `/v1/goods/sup-goods-item/edit/${id}` })
      window.open(newUrl.href, '_blank')
    },
    // 点击删除
    onDeleteClick(id) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiV1DeleteSupGoodsItem({
      //     sup_goods_item_id: id,
      //   }).then((res) => {
      //     if (res.data.code == 0) {
      //       this.getPage(); this.$message.success("操作成功")
      //     } else {
      //       this.$message.error(res.data.message)
      //     }
      //   })
      // }).catch(()=>{})
    },

    // 点击触发 - 货品导入
    onUploadClick() {
      this.$refs.refSupGoodsItemQuantityUploadDialog.showView({ editMode: "insert" })
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      // this.$message.success("操作成功")
    },


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },




    // 更多按钮触发
    onMoreButtonClick(commandObj) {
      // console.log(commandObj)
      switch (commandObj.index) {
        case 'redeclare':
          this.onDeclareClick([commandObj.id], 1)
          break;
      }
    },


    // 点击 导出当前店铺商品
    onExportCurrentShopGoodsClick() {
      var url = `${GlobalConfig.host}api/admin/goods/goods/action/export-shop-goods?shop_id=` + AuthUtils.getCurrentShopId()
      window.open(url) 
    },


    // 点击 修改状态
    onChangeStateClick(state, inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.sup_goods_item_id)
      var apiParams = { ids: ids, goods_item_state: state }
      apiV1SupGoodsItemChangeState(apiParams).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },


    onSyncQuantityClick() {
      apiV1CainiaoGoodsQuantityActionSyncQuantity({}).then((res) => {
        this.getPage(); this.$message.success("操作成功")
      })
    },
    onBindGoodsClick() {
      apiV1CainiaoGoodsQuantityActionBindGoods({}).then((res) => {
        this.getPage(); this.$message.success("操作成功")
      })
    },
    

    

    // ===================================================================================================

    // 实体行样式
    funRowClassName({  row,  rowIndex,}) {
      if (row.entity_type === 'merchant') {
        return 'jxgocs-merchant-row'
      } else if (row.entity_type === 'shop') {
        return 'jxgocs-shop-row'
      }
      return ''
    },

    checkImageUrl(url) {
      return (url == '' || url == null) ? '/static/images/default_image.png' : url
    },


    // 获取校区pair
    // getCampusPairData() {
    //   apiGetCampusPairs().then((res) => {
    //     this.campusPairs = res.data.data
    //   })
    // },

  },
  watch: {},
  mounted() {
    this.$$emitter.on('current-shop-changed', (shop_id) => {
      this.getPage()
    })

    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
.m-main-image{ width:80px; height:80px; margin: 4px 0; }
.m-main-image .el-image { padding: 4px;  max-width: 80px;  max-height: 80px;  width: 100%;  height: 80px; }
.m-main-image .image-slot { display: flex;  justify-content: center;  align-items: center;  width: 100%;  height: 100%;  background: #d4d7de;  color: var(--el-text-color-secondary);  font-size: 30px; }
.m-main-image .image-slot .el-icon { font-size: 30px; }
</style>
