import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 基础信息
// ================================================================================================

// 获取进口地pair
export function apiGetCustomsOriginPairs() {
    return request({
        url: '/api/merchant/basedata/customs-origin-pairs',
        method: 'get',
        token: true,
    })
}

// 获取单位pair
export function apiGetCustomsUnitPairs() {
    return request({
        url: '/api/merchant/basedata/customs-unit-pairs',
        method: 'get',
        token: true,
    })
}

// 获取区域pair
export function apiGetRegionPairs(data) {
    return request({
        url: '/api/merchant/basedata/customs-unit-pairs',
        method: 'get',
        params: data,
        token: true,
    })
}